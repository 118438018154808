import {
  CHANNEL_TYPES,
  IContextualContact,
  ISupportedChannel,
  LOG_LEVEL,
  Logger,
  SearchRecords,
  addContextualContacts,
  enableClickToDial,
  getConfig,
  getPresence,
  getUserDetails,
  initializeComplete,
  logout,
  registerClickToDial,
  registerContextualControls,
  registerOnLogout,
  registerOnPresenceChanged,
  sendNotification,
  setPresence,
  setSupportedChannels
} from '@amc-technology/davinci-api';

import { ConfigurationService } from './configuration.service';
import { Injectable } from '@angular/core';
import { LoggerService } from './logger.service';
import { ReplaySubject } from 'rxjs';
import { TwilioService } from './twilio.service';

@Injectable({
  providedIn: 'root'
})
export class AmcService {
  public config$ = new ReplaySubject<{
    logger: Logger;
    conferenceStatusCallback: string;
    iconPack: string;
    username: string;
  }>(1);

  private logger: Logger;
  private currentWorkmode: string; // Current workmode from twilio sent to Framework
  private initialActivity: string;
  private firstLogin = true;
  private workmodeMap: { [workmode: string]: string }; // workmode to activity sid, and activity sid to workmode
  private frameworkPresenceToTwilio: string; // Framework presence to twilio
  private appTitle: any;

  constructor(private twilio: TwilioService, private configService: ConfigurationService, private loggerService: LoggerService) {
    this.appTitle = null;
    this.initialize();
  }

  async initialize() {
    const serverConfig = await this.configService.loadConfigurationData();
    const davinciConfig = await getConfig();
    this.appTitle = davinciConfig.name;
    const conferenceStatusCallback = davinciConfig.variables.ConferenceStatusCallback as string;
    this.initialActivity = davinciConfig.variables.InitialActivity as string;

    this.workmodeMap = davinciConfig.variables.WorkMode as unknown as {
      [workmode: string]: string;
    };
    // make the map 2 directional
    for (const key of Object.keys(this.workmodeMap)) {
      this.workmodeMap[this.workmodeMap[key]] = key;
    }
    let supportedChannels: ISupportedChannel[] = [];
    if (davinciConfig.variables.EnableSMSChannel && !davinciConfig.variables.EnableVoiceChannel) {
      supportedChannels = [
        {
          channelType: CHANNEL_TYPES.SMS,
          idName: 'sms',
          id: '1'
        }
      ];
    } else if (davinciConfig.variables.EnableVoiceChannel && !davinciConfig.variables.EnableSMSChannel) {
      supportedChannels = [
        {
          channelType: CHANNEL_TYPES.Telephony,
          idName: 'phone',
          id: '0'
        }
      ];
    } else if (davinciConfig.variables.EnableVoiceChannel && davinciConfig.variables.EnableSMSChannel) {
      supportedChannels = [
        {
          channelType: CHANNEL_TYPES.Telephony,
          idName: 'phone',
          id: '0'
        },
        {
          channelType: CHANNEL_TYPES.SMS,
          idName: 'sms',
          id: '1'
        }
      ];
    } else {
      supportedChannels = [
        {
          channelType: CHANNEL_TYPES.Telephony,
          idName: 'phone',
          id: '0'
        }
      ];
    }
    // TODO: use push to make setSupportedChannels less confusing
    setSupportedChannels(supportedChannels);
    registerContextualControls(async (contact: IContextualContact, channelType: CHANNEL_TYPES) => {
      const functionName = `registerContextualControlsCallback`;
      try {
        this.loggerService.logger.logInformation(`${functionName} : Recieved contextual event. Contact: ${JSON.stringify(contact)} : ChannelType: ${channelType}`);
        if (channelType === CHANNEL_TYPES.SMS && davinciConfig.variables.EnableSMSChannel) {
          this.twilio.sendOutboundSMS(contact.displayName);
        } else {
          await this.twilio.initiateOutbound(contact.displayName);
        }
      } catch (error) {
        this.loggerService.logger.logError(`${functionName} ; Failed to handle contextual event. Error: ${JSON.stringify(error)}`);
      }
    });

    enableClickToDial(true);

    registerClickToDial(async (phoneNumber: string, records: SearchRecords, channelType: CHANNEL_TYPES) => {
      const functionName = `clickToDialCallback`;
      try {
        this.loggerService.logger.logInformation(`${functionName} : Recieved Click to dial event. PhoneNumber: ${phoneNumber} : ChannelType: ${channelType}`);
        if (channelType === CHANNEL_TYPES.SMS && davinciConfig.variables.EnableSMSChannel) {
          await this.twilio.sendOutboundSMS(phoneNumber);
        } else if (channelType === CHANNEL_TYPES.Telephony && davinciConfig.variables.EnableVoiceChannel) {
          await this.twilio.initiateOutbound(phoneNumber);
        }
      } catch (error) {
        this.loggerService.logger.logError(`${functionName} : Failed to process click to dial. Error: ${JSON.stringify(error)}`);
      }
    }).catch((error) => this.loggerService.logger.logError(`registerClickToDial : Callback failed. Error: ${JSON.stringify(error)}`));

    registerOnPresenceChanged(async (presence, reason, initiatingApp) => {
      if (initiatingApp !== this.appTitle) {
        if (this.currentWorkmode !== presence) {
          if (this.workmodeMap[presence]) {
            this.loggerService.logger.logDebug('setActivity: ' + this.workmodeMap[presence]);
            this.twilio.setActivity(this.workmodeMap[presence]);
          }
        } else {
          setPresence(presence, reason);
        }
      }
    });

    registerOnLogout(async (reason) => {
      const functionName = `registerOnLogout`;
      try {
        this.loggerService.logger.logDebug(`${functionName} : Recieved logout event from framework. Reason: ${reason}`);
        this.twilio.setActivity(this.workmodeMap.Logout);
        await this.loggerService.logger.pushLogsAsync();
      } catch (e) {
        this.loggerService.logger.logError(`${functionName} : Failed to set activity on logout. Error: ${JSON.stringify(e)}`);
      }
    });

    await initializeComplete(this.loggerService.logger);
    addContextualContacts([]);
    const userDetails = await getUserDetails();
    const username = userDetails.username.replace(/[@]/g, '_at_').replace(/\./g, '_dot_');
    this.config$.next({
      logger: this.loggerService.logger,
      conferenceStatusCallback,
      iconPack: serverConfig.iconPack,
      username
    });

    this.twilio.getActivity().subscribe(this.activityChanged.bind(this));
  }

  async activityChanged(activitySid: string) {
    const functionName = `activityChanged`;
    try {
      this.loggerService.log(LOG_LEVEL.Debug, functionName, `Presence Changed from Twilio.`, this.workmodeMap[activitySid]);
      const workmode = this.workmodeMap[activitySid];
      if (workmode !== this.currentWorkmode) {
        if (workmode === 'Logout') {
          this.loggerService.log(LOG_LEVEL.Debug, functionName, `Logging User Out.`, workmode);
          await this.loggerService.logger.pushLogsAsync();
          logout();
        } else {
          this.loggerService.log(LOG_LEVEL.Debug, functionName, `Setting Presence.`, workmode);
          this.currentWorkmode = workmode;
          setPresence(workmode);
        }
      }
    } catch (error) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Failed to set activity.`, error);
    }
  }
}
