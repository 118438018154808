/* eslint-disable no-shadow */

import { ITask } from './Task';

export interface ITwilioInteraction {
  channel: CHANNELS;
  taskSid: string;
  reservationSid: string;
  conferenceSid?: string;
  conversationSid?: string;
  outboundSMSNumber?: string;
  parties: any[];
  reservation?: IReservation;
  connection?: IConnection;
  chat?: {
    channel: any;
    messages: any[];
  };
  isHeld?: boolean;
  isBlindTransfering?: boolean;
  isWarmTransfering?: boolean;
  confirmingWarmTransfer?: boolean;
  incomingWarmTransfer?: boolean;
  isWrapup?: boolean;
  conferenceFriendlyName?: string;
}

export interface IConnection {
  direction: CONNECTION_DIRECTION;
  parameters: {
    AccountSid: string;
    CallSid: string;
    TaskSid: string;
    From: string; // phone number
    To: string;
    customParameters: any;
  };
  message?: { [key: string]: any };
  accept?: (handlerOrConstraints: any) => void;
  cancel?: (handler: any) => void;
  disconnect?: (handler?: any) => void;
  error?: (handler: any) => void;
  getLocalStream?: () => any;
  getRemoteStream?: () => any;
  ignore?: (handler: any) => void;
  isMuted?: () => boolean;
  mute?: (shouldMute: boolean) => void;
  postFeedback?: (score, issue) => any;
  reject?: (handler: any) => void;
  sendDigits?: (digits) => any;
  status?: () => any;
  unmute?: () => any;
  volume?: (handler: any) => void;
}

export enum CHANNELS {
  'SMS' = 'SMS',
  'Phone' = 'Phone',
  'Chat' = 'Chat'
}

// TODO: Update this to match the TaskRouter SDK
export interface IReservation {
  sid: string;
  taskSid: string;
  task: ITask;
  reservationStatus: RESERVATION_STATE;
  dequeue: (params: any) => void;
  [key: string]: any;
  // This is not complete
}

export enum CONNECTION_DIRECTION {
  'INCOMING' = 'INCOMING',
  'OUTBOUND' = 'OUTBOUND'
}

// TaskRouter Events https://www.twilio.com/docs/taskrouter/js-sdk/workspace/worker#worker-events
export enum WORKER_EVENT {
  Ready = 'ready',
  ActivityUpdate = 'activity.update',
  AttributesUpdate = 'attributes.update',
  CapacityUpdate = 'capacity.update',
  Created = 'reservation.created',
  Accepted = 'reservation.accepted',
  Rejected = 'reservation.rejected',
  Timeout = 'reservation.timeout',
  Canceled = 'reservation.canceled',
  Rescinded = 'reservation.rescinded',
  TokenExpired = 'token.expired',
  Connected = 'connected',
  Disconnected = 'disconnected',
  Error = 'error',
  TaskUpdated = 'task.updated'
}

export enum WORKER_STATE {
  connected = 'connected',
  disconnected = 'disconnected',
  pending = 'pending'
}

export enum RESERVATION_STATE {
  accepted = 'accepted',
  canceled = 'canceled',
  completed = 'completed',
  pending = 'pending',
  rejected = 'rejected',
  rescinded = 'rescinded',
  timeout = 'timeout',
  wrapup = 'wrapup'
}

export enum TASK_STATE {
  pending = 'pending',
  reserved = 'reserved',
  assigned = 'assigned',
  wrapping = 'wrapping',
  completed = 'completed',
  canceled = 'canceled'
}

// Conversation Events https://sdk.twilio.com/js/conversations/releases/2.3.0/docs/classes/Conversation.html#state
export enum CONVERSATION_EVENT {
  connectionError = 'connectionError',
  connectionStateChanged = 'connectionStateChanged',
  conversationAdded = 'conversationAdded',
  conversationJoined = 'conversationJoined',
  conversationLeft = 'conversationLeft',
  conversationRemoved = 'conversationRemoved',
  conversationUpdated = 'conversationUpdated',
  initFailed = 'initFailed',
  initialized = 'initialized',
  messageAdded = 'messageAdded',
  messageRemoved = 'messageRemoved',
  messageUpdated = 'messageUpdated', // Can be used for message recieved
  participantJoined = 'participantJoined',
  participantLeft = 'participantLeft',
  participantUpdated = 'participantUpdated',
  removed = 'removed',
  typingEnded = 'typingEnded',
  typingStarted = 'typingStarted',
  updated = 'updated',
  pushNotification = 'pushNotification',
  tokenAboutToExpire = 'tokenAboutToExpire',
  tokenExpired = 'tokenExpired',
  userSubscribed = 'userSubscribed',
  userUnsubscribed = 'userUnsubscribed',
  userUpdated = 'userUpdated'
}

// TODO: Determine other states
export enum CONVERSATION_STATE {
  connecting = 'connecting',
  connected = 'connected',
  disconnected = 'disconnected',
  reconnecting = 'reconnecting',
  failed = 'failed',
  unitialized = 'unitialized'
}

// Todo: Add Enum for voice/devices
