import { IInteraction } from '@amc-technology/davinci-api';
import { Injectable } from '@angular/core';
import { LOG_LEVEL } from '@amc-technology/davinci-api';
import { LoggerService } from './logger.service';

@Injectable()
export class StorageService {
  public onFocusTaskId: string;
  public conversationCleanupSent: number;

  constructor(private loggerService: LoggerService) {
    this.onFocusTaskId = '';
  }

  public setOnFocus(taskId: string) {
    this.onFocusTaskId = taskId;
    this.storeToLocalStorage();
  }

  public setConversationCleanup() {
    const functionName = `setConversationCleanup`;
    try {
      this.conversationCleanupSent = Date.now();
      this.storeToLocalStorage();
    } catch (e) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Failed to set conversation cleanup.`, e);
    }
  }

  public getConversationCleanup() {
    const functionName = `getConversationCleanup`;
    try {
      return parseInt(localStorage.getItem(`conversationCleanupSent`), 10);
    } catch (e) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Failed to get conversation cleanup.`, e);
    }
  }

  private storeToLocalStorage() {
    const functionName = `storeToLocalStorage`;
    try {
      this.loggerService.logger.logInformation(`${functionName} : Storing to local storage.`);
      if (this.conversationCleanupSent) {
        localStorage.setItem(`conversationCleanupSent`, this.conversationCleanupSent.toString());
      }
      if (this.onFocusTaskId) {
        const scenarioRecord = JSON.stringify({onFocusTaskId: this.onFocusTaskId});
        localStorage.setItem('scenario', scenarioRecord);
      }
    } catch (e) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Failed to store to local storage.`, e);
    }
  }

  public syncWithLocalStorage() {
    const scenarioRecord = localStorage.getItem('scenario');
    const browserStorage = JSON.parse(scenarioRecord);
    if (browserStorage) {
      this.onFocusTaskId = browserStorage.onFocusTaskId;
    }
  }
}
