import { ERROR_CODE, LOG_LEVEL, LOG_SOURCE, Logger, getDaVinciAgentConfig } from '@amc-technology/davinci-api';
import { ILoggerConfiguration, defaultLoggerConfiguration } from '@amc-technology/davinci-api/dist/models/LoggerConfiguration';

import { ConfigurationService } from './configuration.service';
import { Injectable } from '@angular/core';

@Injectable()
export class LoggerService {
  private localLog = false; // Set this to true if you want to print logs to the console
  private _logger?: Logger;

  constructor(private configService: ConfigurationService) {}

  get logger() {
    if (!this._logger) {
      throw new Error('Logger not initialized!');
    }
    return this._logger;
  }

  async initialize() {
    const serverConfig = await this.configService.loadConfigurationData();
    try {
      // Get raw config. Framework does not perform data validation intentionally
      const rawConfig = await getDaVinciAgentConfig();

      // Perform data validation, revert to defaults if configs are invalids
      // or undefined

      let logLevel = parseInt(rawConfig?.variables?.['Log Level']?.toString(), 10);

      logLevel = isNaN(logLevel) ? defaultLoggerConfiguration['Log Level'] : logLevel;

      let maxLength = parseInt(rawConfig?.['Console Logger']?.variables?.['Max Length']?.toString(), 10);

      maxLength = isNaN(maxLength) ? defaultLoggerConfiguration['Console Logger']['Max Length'] : maxLength;
      const loggerConfig: ILoggerConfiguration = {
        'Log Level': logLevel,
        'Logger Type': rawConfig?.variables?.['Logger Type']?.toString() || defaultLoggerConfiguration['Logger Type'],
        'Premise Logger URL': rawConfig?.variables?.['Premise Logger URL']?.toString() || defaultLoggerConfiguration['Premise Logger URL'],
        'Console Logger': {
          'Max Length': maxLength
        }
      };
      this._logger = new Logger('DaVinciContactCenter', false, serverConfig.loggerApiUrl);

      this._logger.setConfiguration(loggerConfig);
    } catch (err) {
      this._logger = new Logger('DaVinciContactCenter', false, serverConfig.loggerApiUrl);
      this._logger.logCritical('loggerService.initialize(): Error creating logger!');
    }
  }

  /**
   * Logs a message to the loggerApi
   *
   * @param {LOG_LEVEL} logLevel - The level of the log
   * @param {string} fName - Name of the function logging the message
   * @param {string} message - Message to log
   * @param {*} [object] - Optional object to log
   * @param {ERROR_CODE} [errorCode] - Optional error code
   * @param {Date} [localTime] - Optional local time
   * @memberof LoggerService
   */
  async log(logLevel: LOG_LEVEL, fName: string, message: string, object?: any, errorCode?: ERROR_CODE, localTime?: Date) {
    const functionName = 'log';
    try {
      if (this.localLog) {
        this.localLogMessage(logLevel, fName, message, object, errorCode, localTime);
      }
      switch (logLevel) {
        case LOG_LEVEL.Loop:
          this._logger.logLoop(`${fName} | ${message}` + (object ? ` | ${JSON.stringify(object, this.stringifyReplacer(), 2)}` : ``), errorCode, localTime);
          break;
        case LOG_LEVEL.Trace:
          this._logger.logTrace(`${fName} | ${message}` + (object ? ` | ${JSON.stringify(object, this.stringifyReplacer(), 2)}` : ``), errorCode, localTime);
          break;
        case LOG_LEVEL.Information:
          this._logger.logInformation(`${fName} | ${message}` + (object ? ` | ${JSON.stringify(object, this.stringifyReplacer(), 2)}` : ``), errorCode, localTime);
          break;
        case LOG_LEVEL.Warning:
          this._logger.logWarning(`${fName} | ${message}` + (object ? ` | ${JSON.stringify(object, this.stringifyReplacer(), 2)}` : ``), errorCode, localTime);
          break;
        case LOG_LEVEL.Error:
          this._logger.logError(`${fName} | ${message}` + (object ? ` | ${JSON.stringify(object, this.stringifyReplacer(), 2)}` : ``), errorCode, localTime);
          break;
        case LOG_LEVEL.Critical:
          this._logger.logCritical(`${fName} | ${message}` + (object ? ` | ${JSON.stringify(object, this.stringifyReplacer(), 2)}` : ``), errorCode, localTime);
          break;
        case LOG_LEVEL.Debug:
          this._logger.logDebug(`${fName} | ${message}` + (object ? ` | ${JSON.stringify(object, this.stringifyReplacer(), 2)}` : ``), errorCode, localTime);
          break;
        default:
          this._logger.logInformation(`${fName} | ${message}` + (object ? ` | ${JSON.stringify(object, this.stringifyReplacer(), 2)}` : ``), errorCode, localTime);
          break;
      }
    } catch (e) {
      this._logger.logError(`${functionName} - Failed to log ${logLevel} from ${fName}. Error: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
      console.log(`${functionName} - Failed to log ${logLevel} from ${fName}. Error: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
    }
  }

  /**
   * This function logs a message to the console that is color coded based off of its LOG_LEVEL
   *
   * @private
   * @param {LOG_LEVEL} logLevel
   * @param {string} fName
   * @param {string} message
   * @param {*} [object]
   * @param {ERROR_CODE} [errorCode]
   * @param {Date} [localTime]
   * @memberof LoggerService
   */
  private localLogMessage(logLevel: LOG_LEVEL, fName: string, message: string, object?: any, errorCode?: ERROR_CODE, localTime?: Date) {
    const functionName = 'localLogMessage';
    try {
      let backgroundColor = '#003300';
      let textColor = '#00ff00';

      switch (logLevel) {
        case LOG_LEVEL.Loop:
        case LOG_LEVEL.Trace:
          backgroundColor = '#000033';
          textColor = '#0077ff';
          break;
        case LOG_LEVEL.Information:
        case LOG_LEVEL.Debug:
          backgroundColor = '#003300';
          textColor = '#00ff00';
          break;
        case LOG_LEVEL.Warning:
        case LOG_LEVEL.Error:
        case LOG_LEVEL.Critical:
          backgroundColor = '#330000';
          textColor = '#ff0000';
          break;
        default:
          backgroundColor = '#003300';
          textColor = '#00ff00';
          break;
      }
      const fontWeight = 'bold';
      if (object != null && typeof object === 'object') {
        console.log(`%c${fName} | ${LOG_LEVEL[logLevel]} | ${message}`, `background: ${backgroundColor}; color: ${textColor}; font-weight: ${fontWeight};`);
        const printableObject = {
          functionName: fName,
          object: object
        };
        console.log(printableObject);
        console.log(`%c${fName} | ${LOG_LEVEL[logLevel]}`, `background: ${backgroundColor}; color: ${textColor}; font-weight: ${fontWeight};`);
      } else {
        console.log(`%c${fName} | ${LOG_LEVEL[logLevel]} | ${message} | ${JSON.stringify(object, this.stringifyReplacer(), 2)}`, `background: ${backgroundColor}; color: ${textColor}; font-weight: ${fontWeight};`);
      }
    } catch (e) {
      console.log(`${functionName} - Failed to log ${logLevel} from ${fName}. Error: ${JSON.stringify(e, Object.getOwnPropertyNames(e))}`);
    }
  }

  private stringifyReplacer() {
    const ancestors = [];
    return function (key, value) {
      if (typeof value !== 'object' || value === null) {
        if (value instanceof Set) {
          return [...value];
        }
        if (key === 'promise') {
          return {};
        }
        return value;
      } else {
        while (ancestors.length > 0 && ancestors[ancestors.length - 1] !== this) {
          ancestors.pop();
        }
        if (ancestors.includes(value)) {
          return '[Circular]';
        }
        ancestors.push(value);
        return value;
      }
    };
  }
}
