import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { CHANNELS, CONNECTION_DIRECTION, CONVERSATION_EVENT, CONVERSATION_STATE, IConnection, IReservation, ITwilioInteraction, RESERVATION_STATE, WORKER_EVENT, WORKER_STATE } from './model/TwilioInteraction';
import {
  CHANNEL_TYPES,
  CONTEXTUAL_OPERATION_TYPE,
  IField,
  IGetPresenceResult,
  IInteraction,
  INTERACTION_DIRECTION_TYPES,
  INTERACTION_STATES,
  LOG_LEVEL,
  NOTIFICATION_TYPE,
  RecordItem,
  contextualOperation,
  getConfig,
  getPresence,
  getUserDetails,
  sendNotification,
  setInteraction
} from '@amc-technology/davinci-api';
import { Connection, Device } from 'twilio-client';

import Base64 from 'crypto-js/enc-base64';
import { Client as ConversationsClient } from '@twilio/conversations';
import { HttpClient } from '@angular/common/http';
import { ITask } from './model/Task';
import { Injectable } from '@angular/core';
import { LoggerService } from './logger.service';
import { StorageService } from './twilioStorage.service';
import { TASK_STATE } from './model/TwilioInteraction';
import sha256 from 'crypto-js/sha256';

// eslint-disable-next-line @typescript-eslint/naming-convention
declare const Twilio: { TaskRouter: any };

@Injectable({
  providedIn: 'root'
})
export class TwilioService {
  // TODO: split this into configurations vs state variables
  private worker: any;
  private devices: Device[] = new Array<Device>(5);
  private voiceToken: any;
  private twilioInteractions: {
    [taskSid: string]: {
      interaction: ITwilioInteraction;
      subject: BehaviorSubject<ITwilioInteraction>
    };
  } = {};

  private tasks$ = new ReplaySubject<BehaviorSubject<ITwilioInteraction>>(10);
  private activity$ = new ReplaySubject<string>(1);
  private currentActivity: string;
  private initialActivity: string;
  private username: string;
  private accountSid: any;
  private authToken: any;
  private workerAttributes = {};
  private phoneNumberFormat = {};
  private sendingOutboundSMS = false;
  private workSpaceSid: any;
  private directWorkflowSid: any;
  private chatServiceSid: any;
  private chatApiSid: any;
  private retrievedPhoneNumber = ''; // TODO: see if this can be removed | This is used by voice and should probably be refactored/removed
  private chatApiSecret: any;
  private chatToken: string;
  private taskRouterToken: string;
  public conversationClient: ConversationsClient;
  private voiceApplicationSid: any;
  private enableSMSChannel = false;
  private enableVoiceChannel = false;
  private saveSMSTranscript = false;
  private outboundNumber = '';
  private holdOnTransfer = true;
  private statusCallback: any;
  private incomingPhoneRinger: any;
  private incomingChatSMSRinger: any;
  private conferenceFriendlyName: string; // TODO: see if this can be removed | This is used by voice and should probably be refactored/removed
  private outboundTo: string; // TODO: see if this can be removed | This seems to do nothing but it is used by setConnection for voice, so it should probably be refactored/removed
  private workmodeMap: { [workmode: string]: string }; // workmode to activity sid, and activity sid to workmode
  private previousActivity: string;
  private warmTransferTargetSid: string; // TODO: refactor with voice | This is used by voice and should probably be refactored/removed
  private warmTransferCheckInterval: any; // TODO: refactor with voice | This is used to ensure that a warm transfer task isn't canceled or deleted before the second agent accepts it
  private warmTransferCheckTimer = 2000; // TODO: refactor with voice | This is used to ensure that a warm transfer task isn't canceled or deleted before the second agent accepts it
  public userEnabledSound = false;
  public isChrome = false;
  public outboundSMSNumber: string;
  private cadKeyDisplayMapping: any;
  private checkConversations = false;
  private checkConversationsUrl: string;
  private checkConversationsTimer: number;
  private outboundPresenceEnabled = false;
  private outboundPresenceOnlySid: string;
  private messagePageSize = 5;
  private lastOutboundNumber: { phoneNumber: string; timestamp: number } = { phoneNumber: '', timestamp: 0 };
  private outboundTimeBuffer = 3000;
  private taskRouterClientInitialized = false;
  private voiceClientInitialized = false;
  private voiceDevicesInitialized = 0;
  private conversationClientInitialized = false;
  private allInitialized = false;
  private previousConversationState: CONVERSATION_STATE = CONVERSATION_STATE.unitialized;
  private previousTaskrouterState: WORKER_STATE;
  private reInitTaskRouterListeners = false;
  private reInitConversationListeners = false;

  constructor(private http: HttpClient, private loggerService: LoggerService, private storageService: StorageService) {
    this.cadKeyDisplayMapping = null;
    this.initialize();
  }

  /**
   * Initializes the Twilio Service
   *
   * @memberof TwilioService
   */
  async initialize() {
    try {
      this.storageService.syncWithLocalStorage();
      const davinciConfig = await getConfig();
      await this.getDavinciConfigs(davinciConfig);
      const userDetails = await getUserDetails();
      this.username = userDetails.username.replace(/[@]/g, '_at_').replace(/\./g, '_dot_');
      await this.getTokens();
    } catch (error) {
      // TODO: Use Ahmad's logger service changes to make sure its finished initializing before logging
      console.log(`Error initializing Twilio Service: ${JSON.stringify(error)}, Message: ${error.message}`);
    }
  }

  /**
   * Gets the app configuration from the DaVinci Config object
   *
   * @param {*} davinciConfig The DaVinci Config object
   * @memberof TwilioService
   */
  // eslint-disable-next-line max-statements
  public async getDavinciConfigs(davinciConfig: any) {
    const functionName = `getDaVinciConfigs`;
    try {
      // General Configs
      this.loggerService.log(LOG_LEVEL.Debug, functionName, `Getting DaVinci Configs`);
      this.loggerService.log(LOG_LEVEL.Trace, functionName, `DaVinci Configs: `, davinciConfig);
      if (davinciConfig.variables) {
        if (davinciConfig.variables.hasOwnProperty('AccountSid')) {
          this.accountSid = davinciConfig.variables['AccountSid'];
        }
        if (davinciConfig.variables.hasOwnProperty('AuthToken')) {
          this.authToken = davinciConfig.variables['AuthToken'];
        }
        if (davinciConfig.variables.hasOwnProperty('WorkSpaceSid')) {
          this.workSpaceSid = davinciConfig.variables['WorkSpaceSid'];
        }
        if (davinciConfig.variables.hasOwnProperty('DirectWorkflowSid')) {
          this.directWorkflowSid = davinciConfig.variables['DirectWorkflowSid'];
        }
        if (davinciConfig.variables.hasOwnProperty('InitialActivity')) {
          this.initialActivity = davinciConfig.variables['InitialActivity'];
        }
        if (davinciConfig.variables.hasOwnProperty('PhoneNumberFormat')) {
          const configPhoneFormat = davinciConfig.variables['PhoneNumberFormat'];
          if (typeof configPhoneFormat === 'string') {
            const tempFormat = String(configPhoneFormat).toLowerCase();
            this.phoneNumberFormat[tempFormat] = tempFormat;
          } else {
            this.phoneNumberFormat = configPhoneFormat;
          }
        }
        if (davinciConfig.variables.hasOwnProperty('WorkMode')) {
          this.workmodeMap = davinciConfig.variables['WorkMode'] as unknown as { [workmode: string]: string };
          for (const key of Object.keys(this.workmodeMap)) {
            this.workmodeMap[this.workmodeMap[key]] = key;
          }
        }
        // Worker Attribute Configs
        if (davinciConfig.variables.hasOwnProperty('WorkerAttributes')) {
          for (const [key, value] of Object.entries(davinciConfig.variables.WorkerAttributes)) {
            this.workerAttributes[key] = value;
          }
        }

        // Configs to enable or disable SMS and voice channels
        if (davinciConfig.variables.hasOwnProperty('EnableSMSChannel')) {
          this.enableSMSChannel = davinciConfig.variables['EnableSMSChannel'];
        }
        if (davinciConfig.variables.hasOwnProperty('EnableVoiceChannel')) {
          this.enableVoiceChannel = davinciConfig.variables['EnableVoiceChannel'];
        }

        // CAD Display Configs
        if (davinciConfig.hasOwnProperty('CADDisplay')) {
          if (davinciConfig.CADDisplay.variables.hasOwnProperty('DisplayKeyList')) {
            this.cadKeyDisplayMapping = davinciConfig.CADDisplay.variables['DisplayKeyList'];
          }
        }

        // Phone Configs
        if (davinciConfig.hasOwnProperty('Phone')) {
          this.voiceApplicationSid = davinciConfig['Phone'].variables['VoiceApplicationSid'];
          this.statusCallback = davinciConfig.variables.ConferenceStatusCallback;
          const userAttributes = await getUserDetails();
          if (userAttributes.attributes !== '') {
            const formattedAttributes = JSON.parse(userAttributes.attributes);
            for (const item in formattedAttributes) {
              if (formattedAttributes.hasOwnProperty(item)) {
                this.workerAttributes[formattedAttributes[item].name] = formattedAttributes[item].value;
                if (formattedAttributes[item].name === 'OutboundNumber') {
                  this.outboundNumber = formattedAttributes[item].value;
                }
              }
            }
          }

          if (this.outboundNumber === '') {
            this.outboundNumber = davinciConfig['Phone'].variables['OutboundNumber'];
          }
          if (davinciConfig['Phone'].variables.hasOwnProperty('HoldOnTransfer')) {
            this.holdOnTransfer = davinciConfig['Phone'].variables['HoldOnTransfer'];
          }
          if (davinciConfig['Phone'].variables.hasOwnProperty('WarmTransferTimer')) {
            this.warmTransferCheckTimer = davinciConfig['Phone'].variables['WarmTransferTimer'];
          }
          if (davinciConfig['Phone'].variables.hasOwnProperty('TelephonyRinger')) {
            this.incomingPhoneRinger = new Audio(davinciConfig['Phone'].variables['TelephonyRinger']);
            this.incomingPhoneRinger.loop = true;
            if (this.incomingPhoneRinger && !this.incomingPhoneRinger.paused) {
              this.incomingPhoneRinger.pause();
            }
          }
        }

        // Chat and SMS Configs
        if (davinciConfig.hasOwnProperty('ChatSMS')) {
          if (davinciConfig['ChatSMS'].variables.hasOwnProperty('ChatServiceSid')) {
            this.chatServiceSid = davinciConfig['ChatSMS'].variables['ChatServiceSid'];
          }
          if (davinciConfig['ChatSMS'].variables.hasOwnProperty('ChatApiSid')) {
            this.chatApiSid = davinciConfig['ChatSMS'].variables['ChatApiSid'];
          }
          if (davinciConfig['ChatSMS'].variables.hasOwnProperty('ChatApiSecret')) {
            this.chatApiSecret = davinciConfig['ChatSMS'].variables['ChatApiSecret'];
          }
          if (davinciConfig['ChatSMS'].variables.hasOwnProperty('ChatSMSRinger')) {
            this.incomingChatSMSRinger = new Audio(davinciConfig['ChatSMS'].variables['ChatSMSRinger']);
            this.incomingChatSMSRinger.loop = true;
            if (this.incomingChatSMSRinger && !this.incomingChatSMSRinger.paused) {
              this.incomingChatSMSRinger.pause();
            }
          }
          if (davinciConfig['ChatSMS'].variables.hasOwnProperty('TaskRecovery')) {
            this.checkConversations = davinciConfig['ChatSMS'].variables['TaskRecovery'];
          }
          if (davinciConfig['ChatSMS'].variables.hasOwnProperty('TaskRecoveryUrl')) {
            this.checkConversationsUrl = davinciConfig['ChatSMS'].variables['TaskRecoveryUrl'];
          }
          if (davinciConfig['ChatSMS'].variables.hasOwnProperty('TaskRecoveryTimer')) {
            this.checkConversationsTimer = davinciConfig['ChatSMS'].variables['TaskRecoveryTimer'];
          }
          if (davinciConfig['ChatSMS'].variables.hasOwnProperty('SaveSMSTranscript')) {
            this.saveSMSTranscript = davinciConfig['ChatSMS'].variables['SaveSMSTranscript'];
          }
          if (davinciConfig['ChatSMS'].variables.hasOwnProperty('MessagePageSize')) {
            this.messagePageSize = davinciConfig['ChatSMS'].variables['MessagePageSize'];
          }
        }

        if (davinciConfig.hasOwnProperty('Outbound Presence')) {
          if (davinciConfig['Outbound Presence'].variables.hasOwnProperty('EnableOutboundPresence')) {
            this.outboundPresenceEnabled = davinciConfig['Outbound Presence'].variables['EnableOutboundPresence'];
          }
          if (davinciConfig['Outbound Presence'].variables.hasOwnProperty('PresenceName')) {
            this.outboundPresenceOnlySid = this.workmodeMap[davinciConfig['Outbound Presence'].variables['PresenceName']];
          }
          if (davinciConfig['Outbound Presence'].variables.hasOwnProperty('OutboundTimeBuffer')) {
            this.outboundTimeBuffer = davinciConfig['Outbound Presence'].variables['OutboundTimeBuffer'];
          }
        }
      } else {
        this.loggerService.log(LOG_LEVEL.Warning, functionName, `No DaVinci configurations found.`);
      }
    } catch (e) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Error setting configurations.`, e);
    }
  }

  /**
   * Calls the Backend to get the tokens for Taskrouter, Conversations, and Devices
   *
   * @memberof TwilioService
   */
  public async getTokens() {
    const functionName = `getTokens`;
    try {
      this.loggerService.log(LOG_LEVEL.Debug, functionName, `Getting Tokens.`);
      const response = await this.http
        .post<ICapabilityTokens>('CapabilityToken', {
          AccountSid: this.accountSid,
          AuthToken: this.authToken,
          WorkspaceSid: this.workSpaceSid,
          DirectWorkFlowSid: this.directWorkflowSid,
          ChatServiceSid: this.chatServiceSid,
          ChatApiSid: this.chatApiSid,
          ChatApiSecret: this.chatApiSecret,
          VoiceApplicationSid: this.voiceApplicationSid,
          WorkerAttributes: this.workerAttributes
        })
        .toPromise();
      this.loggerService.log(LOG_LEVEL.Trace, functionName, `Tokens received.`, Object.keys(response));
      this.taskRouterToken = response.taskRouterToken;
      this.worker = await new Twilio.TaskRouter.Worker(this.taskRouterToken);
      await this.initializeTaskRouter();

      this.currentActivity = response.startingActivitySid;

      if (this.enableSMSChannel) {
        this.chatToken = response.chatToken;
        this.conversationClient = new ConversationsClient(this.chatToken);
        await this.initializeConversationClient();
      }

      if (this.enableVoiceChannel) {
        // TODO: Ensure that voice is initialized correctly
        await this.initializeDevices();
        this.voiceToken = response.voiceToken;
      }
    } catch (e) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Failed to update Tokens.`, e);
    }
  }

  /**
   * Refreshes the tokens for Taskrouter, Conversations, and Devices then updates the tokens for those clients.
   *
   * @memberof TwilioService
   */
  public async refreshTokens() {
    const functionName = `refreshTokens`;
    try {
      this.loggerService.log(LOG_LEVEL.Information, functionName, `Refreshing tokens for agent.`);
      const response = await this.http
        .post<ICapabilityTokens>('CapabilityToken', {
          AccountSid: this.accountSid,
          AuthToken: this.authToken,
          WorkspaceSid: this.workSpaceSid,
          DirectWorkFlowSid: this.directWorkflowSid,
          ChatServiceSid: this.chatServiceSid,
          ChatApiSid: this.chatApiSid,
          ChatApiSecret: this.chatApiSecret,
          VoiceApplicationSid: this.voiceApplicationSid,
          WorkerAttributes: this.workerAttributes
        })
        .toPromise();

      this.taskRouterToken = response.taskRouterToken;
      this.updateTaskRouterToken();

      if (this.enableSMSChannel) {
        this.chatToken = response.chatToken;
        this.updateConversationClientToken();
      }

      // TODO: Handle voice tokens here
    } catch (e) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Failed to refresh tokens.`, e);
    }
  }

  public getRetrievedPhoneNumber() {
    return this.retrievedPhoneNumber;
  }

  public getOutboundSMSNumber() {
    return this.outboundSMSNumber;
  }

  public getTasks() {
    return this.tasks$.asObservable();
  }

  public getActivity() {
    return this.activity$.asObservable();
  }

  public async setActivity(activitySid: string): Promise<void> {
    const functionName = `setActivity`;
    try {
      this.loggerService.log(LOG_LEVEL.Information, functionName, `Setting activity`, activitySid);
      if (activitySid === this.currentActivity) {
        this.loggerService.log(LOG_LEVEL.Information, functionName, `Activity already set as current activity.`);
        return Promise.resolve();
      }
      return await this.worker.update('ActivitySid', activitySid);
    } catch (e) {
      if (this.allInitialized) {
        sendNotification('Unable to set activity.', NOTIFICATION_TYPE.Error);
        this.loggerService.log(LOG_LEVEL.Error, functionName, `Unable to set activity.`, e);
      } else {
        this.loggerService.log(LOG_LEVEL.Trace, functionName, `Unable to set activity, not all services initialized.`, e);
        this.currentActivity = activitySid;
      }
    }
  }

  private async setInitialActivity(activitySid: string) {
    try {
      this.loggerService.log(LOG_LEVEL.Trace, this.setInitialActivity.name, `Setting initial activity.`, activitySid);
      const presence: IGetPresenceResult = await getPresence();
      if (presence.presence === 'Pending') {
        if (this.workmodeMap[activitySid] === 'Logout') {
          this.loggerService.log(LOG_LEVEL.Trace, this.setInitialActivity.name, `Setting initial activity to configured initial activity.`, this.workmodeMap[activitySid]);
          this.worker.update('ActivitySid', this.initialActivity, this.setInitialActivityCallback.bind(this));
        } else {
          this.loggerService.log(LOG_LEVEL.Debug, this.setInitialActivity.name, `Setting Global presence to activity from Twilio.`, this.workmodeMap[presence.presence]);
          this.activity$.next(activitySid);
        }
      } else {
        this.loggerService.log(LOG_LEVEL.Trace, this.setInitialActivity.name, `Setting initial activity to presence from Global Presence.`, this.workmodeMap[presence.presence]);
        this.worker.update('ActivitySid', this.workmodeMap[presence.presence], this.setInitialActivityCallback.bind(this));
      }
    } catch (e) {
      this.loggerService.log(LOG_LEVEL.Error, this.setInitialActivity.name, `Error setting initial activity.`, e);
    }
  }

  private setInitialActivityCallback(error, worker) {
    try {
      if (error) {
        throw error;
      }
      this.loggerService.log(LOG_LEVEL.Trace, this.setInitialActivityCallback.name, `Activity set callback.`, worker.activitySid);
      this.currentActivity = worker.activitySid;
      this.activity$.next(worker.activitySid);
    } catch (e) {
      this.loggerService.log(LOG_LEVEL.Error, this.setInitialActivityCallback.name, `Error setting activity.`, e);
    }
  }

  public wrapup(twilioInteraction: ITwilioInteraction, isWrapup: boolean) {
    const functionName = `wrapup`;
    try {
      this.warmTransferTargetSid = null;
      clearInterval(this.warmTransferCheckInterval);
      twilioInteraction.isWrapup = isWrapup;

      if (twilioInteraction.taskSid != null) {
        this.nextTwilioInteraction(this.twilioInteractions[twilioInteraction.taskSid]);
        this.wrapUpTask(twilioInteraction.taskSid);
      } else {
        this.nextTwilioInteraction(this.twilioInteractions[twilioInteraction.reservation.taskSid]);
        this.wrapUpTask(twilioInteraction.reservation.taskSid);
      }
      twilioInteraction.parties = [];
    } catch (e) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Error in wrapup operation.`, e);
    }
  }

  public generateSetInteraction(twilioInteraction: ITwilioInteraction, state: INTERACTION_STATES) {
    const functionName = `generateSetInteraction`;
    try {
      this.loggerService.log(LOG_LEVEL.Debug, this.generateSetInteraction.name, `Sending Interaction to CRM.`, { reservationSid: twilioInteraction.reservation.sid, taskSid: twilioInteraction.reservation.taskSid, interactionState: state });
      this.loggerService.log(LOG_LEVEL.Trace, functionName, `Sending Interaction to CRM. Interaction:`, twilioInteraction);
      const fields: { [key: string]: IField } = {};
      for (const key in twilioInteraction.reservation.task.attributes) {
        if (key != null) {
          const value = twilioInteraction.reservation.task.attributes[key];
          const field: IField = {
            DevName: key,
            DisplayName: key,
            Value: value
          };
          fields[key] = field;
        }
      }
      const details = new RecordItem('', '', '', fields);
      const isOutbound = twilioInteraction.reservation.task.attributes.hasOwnProperty('outbound') && twilioInteraction.reservation.task.attributes['outbound'];

      details.setPhone('phone', 'phone', twilioInteraction.reservation.task.attributes.from);

      let channelType = CHANNEL_TYPES.Telephony;
      switch (twilioInteraction.channel.toUpperCase()) {
        case CHANNELS.Phone:
          break;
        case CHANNELS.SMS:
          channelType = CHANNEL_TYPES.SMS;
          if (this.saveSMSTranscript) {
            this.loggerService.log(LOG_LEVEL.Information, functionName, `Saving transcript for interaction.`, { reservationSid: twilioInteraction.reservation.sid, taskSid: twilioInteraction.reservation.taskSid });
            this.loggerService.log(LOG_LEVEL.Trace, functionName, `Reservation: `, twilioInteraction.reservation);
            const conversationTranscript = this.getTranscriptFromInteraction(twilioInteraction);
            details.setField('Transcript', '', '', conversationTranscript);
            details.setField('Transcript255', '', '', conversationTranscript.length > 255 ? conversationTranscript.substring(0, 254) : conversationTranscript);
          }
          break;
        case CHANNELS.Chat:
          channelType = CHANNEL_TYPES.Chat;
          break;
        default:
      }
      const interaction: IInteraction = {
        state: state,
        channelType: channelType,
        interactionId: twilioInteraction.reservation.task.sid, // Make sure Voice multiparty supports changing this
        scenarioId: twilioInteraction.reservation.task.sid, // This should be the original TaskSid in a multiparty call scenario
        direction: isOutbound ? INTERACTION_DIRECTION_TYPES.Outbound : INTERACTION_DIRECTION_TYPES.Inbound,
        details,
        userFocus: this.storageService.onFocusTaskId === twilioInteraction.reservation.taskSid
      };
      this.loggerService.log(LOG_LEVEL.Debug, functionName, 'Sending interaction to CRM.', { taskSid: twilioInteraction.reservation.taskSid, reservationSid: twilioInteraction.reservation.sid, state: state });
      this.loggerService.log(LOG_LEVEL.Trace, functionName, 'Sending interaction to CRM.', interaction);
      setInteraction(interaction);
    } catch (error) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Unable to setInteraction.`, error);
    }
  }

  private async getTwilioInteractionByConnection(connection: Connection) {
    const functionName = `getTwilioInteractionByConnection`;
    try {
      this.loggerService.log(LOG_LEVEL.Information, functionName, `Attempting to get interaction from connection.`, connection);
      /* TODO: find a better way to link connection and task
        With the current method you can only have 1 call from a twilio number
        e.g. cant have 2 customers that called the same queue number
      */
      // Search for matching inbound call
      const from = connection.parameters.From;
      let result = Object.values(this.twilioInteractions)
        .filter((el) => el.interaction.reservation)
        .find((el) => from === el.interaction.reservation.task.attributes.from || from === el.interaction.reservation.task.attributes.to);

      // Search for matching outbound call
      if (!result) {
        result = this.twilioInteractions[connection.parameters.CallSid];
      }
      if (!result) {
        result = this.getTwilioInteractionByTaskSid(connection.parameters.TaskSid);
      }
      if (!result) {
        // This checks all of the calls stored in parties of each task
        const interactions = Object.values(this.twilioInteractions);
        for (let i = 0; i < interactions.length; i++) {
          const parties = interactions[i].interaction.reservation.task.attributes.parties;
          for (let j = 0; j < parties.length; j++) {
            const call = await this.http
              .post('GetCaller', {
                AccountSid: this.accountSid,
                AuthToken: this.authToken,
                CallSid: parties[j]
              })
              .toPromise();
            if (call['call']['from'] === from || call['call']['to'] === from) {
              result = interactions[i];
              break;
            }
          }
        }
      }
      this.loggerService.log(LOG_LEVEL.Debug, functionName, `Interaction found.`, result.interaction);
      return result;
    } catch (e) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Unable to get interaction from connection.`, e);
    }
  }

  private getTwilioInteractionByTaskSid(taskSid: string) {
    try {
      this.loggerService.log(LOG_LEVEL.Information, this.getTwilioInteractionByTaskSid.name, `Getting interaction from task sid.`, taskSid);
      let interaction = this.twilioInteractions[taskSid];
      if (!interaction) {
        // TODO: See if this can be removed
        interaction = Object.values(this.twilioInteractions).find((el) => el.interaction.reservation && el.interaction.reservation.taskSid === taskSid);
      }
      if (interaction) {
        this.loggerService.log(LOG_LEVEL.Debug, this.getTwilioInteractionByTaskSid.name, `Interaction found.`, interaction.interaction);
        return interaction;
      } else {
        this.loggerService.log(LOG_LEVEL.Debug, this.getTwilioInteractionByTaskSid.name, `Interaction not found.`);
        return null;
      }
    } catch (e) {
      this.loggerService.log(LOG_LEVEL.Error, this.getTwilioInteractionByTaskSid.name, `Unable to get interaction.`, e);
    }
  }

  private getTwilioInteractionByConversationSid(conversationSid: string) {
    const functionName = `getTwilioInteractionByConversationSid`;
    try {
      this.loggerService.log(LOG_LEVEL.Information, functionName, `Getting interaction from conversationSid. ConversationSid:`, conversationSid);
      const interactionKeys = Object.keys(this.twilioInteractions);
      for (const key of interactionKeys) {
        const currentInteraction = this.twilioInteractions[key];
        if (currentInteraction.interaction.conversationSid === conversationSid) {
          this.loggerService.log(LOG_LEVEL.Debug, functionName, `Interaction found.`, currentInteraction.interaction);
          return currentInteraction;
        }
      }
    } catch (e) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Unable to get interaction.`, e);
    }
  }

  private async createTwilioInteraction(reservation: IReservation): Promise<ITwilioInteraction> {
    const functionName = `createTwilioInteraction`;
    try {
      this.loggerService.log(LOG_LEVEL.Debug, functionName, `Creating new interaction.`, { reservationSid: reservation.sid, taskSid: reservation.taskSid });
      this.loggerService.log(LOG_LEVEL.Trace, functionName, `Creating new interaction. Reservation:`, reservation);
      const isVoice = this.reservationIsVoice(reservation);
      const isOutbound = this.reservationIsOutbound(reservation);
      let conversationSid: string;
      let messages = [];
      if (!isVoice) {
        conversationSid = reservation.task.attributes.conversationSid;
        if (reservation.reservationStatus !== RESERVATION_STATE.pending) {
          messages = await this.getMessagesFromConversation(conversationSid);
        }
      }

      const interaction: ITwilioInteraction = {
        channel: isVoice ? CHANNELS.Phone : CHANNELS.SMS,
        taskSid: reservation.task.sid,
        reservationSid: reservation.sid,
        reservation,
        connection: null,
        parties: [],
        isHeld: false,
        isBlindTransfering: false,
        isWarmTransfering: false,
        conversationSid: conversationSid ? conversationSid : null,
        chat:
          conversationSid && messages && (reservation.reservationStatus !== RESERVATION_STATE.pending || isOutbound)
            ? {
                channel: conversationSid,
                messages: []
              }
            : null
      };
      this.loggerService.log(LOG_LEVEL.Trace, functionName, `Interaction created.`, interaction);
      // TODO: combine this with addTwilioInteraction
      return interaction;
    } catch (e) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Unable to create interaction.`, e);
    }
  }

  public async deleteTwilioInteraction(taskSid: string, reservationSid: string) {
    const functionName = `deleteTwilioInteraction`;
    try {
      this.loggerService.log(LOG_LEVEL.Debug, functionName, `Deleting interaction.`, { taskSid: taskSid, reservationSid: reservationSid });
      this.loggerService.log(LOG_LEVEL.Trace, functionName, `Current interactions`, this.twilioInteractions);
      const twilioInteraction = this.getTwilioInteractionByTaskSid(taskSid);
      if (twilioInteraction) {
        if (twilioInteraction.interaction.reservationSid === reservationSid) {
          this.loggerService.log(LOG_LEVEL.Information, functionName, `Completing interaction.`, { taskSid: taskSid, reservationSid: reservationSid });
          twilioInteraction.subject.complete();
          delete this.twilioInteractions[taskSid];
          if (!this.reservationIsVoice(twilioInteraction.interaction.reservation)) {
            if (twilioInteraction.interaction.reservation.reservationStatus !== RESERVATION_STATE.rejected && twilioInteraction.interaction.reservation.reservationStatus !== RESERVATION_STATE.pending) {
              const activeConversationInteraction = this.getTwilioInteractionByConversationSid(twilioInteraction.interaction.conversationSid);
              if (activeConversationInteraction && activeConversationInteraction.interaction.reservationSid !== reservationSid && activeConversationInteraction.interaction.taskSid !== taskSid) {
                this.loggerService.log(LOG_LEVEL.Trace, functionName, `Conversation still in use by different interaction.`, {
                  taskSid: taskSid,
                  reservationSid: reservationSid,
                  conversationSid: twilioInteraction.interaction.conversationSid,
                  activeTaskSid: activeConversationInteraction.interaction.taskSid,
                  activeReservationSid: activeConversationInteraction.interaction.reservationSid
                });
              } else {
                this.loggerService.log(LOG_LEVEL.Information, functionName, `Leaving conversation.`, { taskSid: taskSid, reservationSid: reservationSid, conversationSid: twilioInteraction.interaction.conversationSid });
                await this.leaveConversation(twilioInteraction.interaction.conversationSid);
              }
            }
          }
          this.generateSetInteraction(twilioInteraction.interaction, INTERACTION_STATES.Disconnected);
          if (this.storageService.onFocusTaskId && this.storageService.onFocusTaskId === taskSid) {
            const taskKeys = Object.keys(this.twilioInteractions);
            if (taskKeys.length > 0) {
              // TODO: Add log saying what task is being focused
              this.storageService.setOnFocus(taskKeys[0]);
            } else {
              this.storageService.setOnFocus('');
            }
          }
        } else {
          this.loggerService.log(LOG_LEVEL.Information, functionName, `No interaction with matching reservationSid.`, { taskSid: taskSid, reservationSid: reservationSid });
        }
      } else {
        this.loggerService.log(LOG_LEVEL.Information, functionName, `Interaction not found.`, { taskSid: taskSid, reservationSid: reservationSid });
      }
    } catch (error) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Unable to delete interaction.`, error);
    }
  }

  private async addTwilioInteraction(interaction: ITwilioInteraction) {
    // TODO: Potentially combine this with nextTwilioInteraction to avoid cases where a task creates multiple scenarios
    const functionName = `addTwilioInteraction`;
    try {
      this.loggerService.log(LOG_LEVEL.Information, functionName, `Adding interaction to tasks.`, { reservationSid: interaction.reservation.sid, taskSid: interaction.reservation.taskSid });
      this.loggerService.log(LOG_LEVEL.Trace, functionName, `Task Object.`, interaction.reservation.task);
      let twilioObject = this.getTwilioInteractionByTaskSid(interaction.reservation.taskSid);
      if (twilioObject == null) {
        // If the twilio interaction doesn't already exist add a new BehaviorSubject to the tasks$ ReplaySubject
        twilioObject = {
          interaction,
          subject: new BehaviorSubject<ITwilioInteraction>(interaction)
        };
        this.twilioInteractions[interaction.taskSid] = twilioObject;
        if (!this.storageService.onFocusTaskId) {
          // TODO: Handle this on page load so that disconnected interactions aren't set as the focus
          this.storageService.setOnFocus(interaction.taskSid);
        }
        this.tasks$.next(twilioObject.subject);
      } else {
        // Otherwise just call nextTwilioInteraction with the updated interaction
        twilioObject = {
          interaction,
          subject: new BehaviorSubject<ITwilioInteraction>(interaction)
        };
        this.loggerService.log(LOG_LEVEL.Trace, functionName, `Interaction already exists.`, interaction.reservation.task);
        await this.nextTwilioInteraction(twilioObject);
      }

      return twilioObject;
    } catch (e) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Unable to add interaction.`, e);
    }
  }

  private async nextTwilioInteraction(interaction: { interaction: ITwilioInteraction; subject: BehaviorSubject<ITwilioInteraction> }) {
    const functionName = `nextTwilioInteraction`;
    try {
      this.loggerService.log(LOG_LEVEL.Debug, functionName, `Updating interaction.`, { reservationSid: interaction.interaction.reservation.sid, taskSid: interaction.interaction.reservation.taskSid });
      const twilioInteraction = await this.getTwilioInteractionByTaskSid(interaction.interaction.taskSid);
      if (twilioInteraction) {
        this.loggerService.log(LOG_LEVEL.Trace, functionName, `Updating UI with new interaction.`, interaction.interaction);
        // await this.updateParties(interaction.interaction);
        twilioInteraction.interaction.reservation = interaction.interaction.reservation;
        twilioInteraction.interaction.reservationSid = interaction.interaction.reservation.sid;
        if (interaction.interaction.connection) {
          twilioInteraction.interaction.connection = interaction.interaction.connection;
        }
        if (interaction.interaction.conversationSid) {
          twilioInteraction.interaction.conversationSid = interaction.interaction.conversationSid;
        }
        this.twilioInteractions[interaction.interaction.taskSid].subject.next(twilioInteraction.interaction); // TODO: This is pass by reference, doesn't need to be gotten from the map again
      } else {
        this.loggerService.log(LOG_LEVEL.Trace, functionName, `Interaction not found.`, this.twilioInteractions);
      }
      this.loggerService.log(LOG_LEVEL.Trace, functionName, `Current interactions`, this.twilioInteractions);
    } catch (e) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Unable to update interaction.`, { error: e, interaction: interaction });
    }
  }

  public async setTimeoutAsPromise(timeout: number): Promise<void> {
    return new Promise((accept, reject) => {
      window.setTimeout(() => accept(), timeout);
    });
  }

  public async returnToPreviousPresence() {
    const functionName = `returnToPreviousPresence`;
    try {
      this.loggerService.log(LOG_LEVEL.Information, functionName, `Returning to previous presence.`, this.previousActivity);
      if (this.previousActivity != null) {
        await this.setActivity(this.previousActivity);
        this.previousActivity = null;
      }
    } catch (e) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Unable to return to previous presence.`, e);
    }
  }

  public playRinger(isVoice: boolean = false) {
    const functionName = `playRinger`;
    try {
      if ((this.incomingChatSMSRinger !== undefined && !this.isChrome) || (this.incomingChatSMSRinger !== undefined && this.isChrome && this.userEnabledSound)) {
        if (isVoice && this.incomingPhoneRinger !== undefined && this.incomingPhoneRinger.paused) {
          this.loggerService.log(LOG_LEVEL.Debug, functionName, `Playing voice ringer.`);
          this.incomingPhoneRinger.play();
        }
        if (!isVoice && this.incomingChatSMSRinger !== undefined && this.incomingChatSMSRinger.paused) {
          this.loggerService.log(LOG_LEVEL.Debug, functionName, `Playing SMS ringer.`);
          this.incomingChatSMSRinger.play();
        }
      }
    } catch (e) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Unable to play ringer.`, e);
    }
  }

  // TODO: Add a message notification ringer for SMS

  public pauseRinger() {
    const functionName = `pauseRinger`;
    try {
      if (this.incomingPhoneRinger && !this.incomingPhoneRinger.paused) {
        this.loggerService.log(LOG_LEVEL.Debug, functionName, `Pausing voice ringer.`);
        this.incomingPhoneRinger.pause();
      }
      if (this.incomingChatSMSRinger && !this.incomingChatSMSRinger.paused) {
        this.loggerService.log(LOG_LEVEL.Debug, functionName, `Pausing SMS ringer.`);
        this.incomingChatSMSRinger.pause();
      }
    } catch (e) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Unable to pause ringer.`, e);
    }
  }

  private checkIfAllInitialized() {
    const functionName = `checkIfAllInitialized`;
    try {
      this.loggerService.log(LOG_LEVEL.Debug, functionName, `Checking if all initialized.`, {
        taskRouterClientInitialized: this.taskRouterClientInitialized,
        voiceClientInitialized: this.voiceClientInitialized,
        conversationClientInitialized: this.conversationClient.connectionState === CONVERSATION_STATE.connected && this.conversationClientInitialized
      });
      if (
        this.taskRouterClientInitialized &&
        (!this.enableVoiceChannel || this.voiceClientInitialized) &&
        (!this.enableSMSChannel || (this.conversationClient.connectionState === CONVERSATION_STATE.connected && this.conversationClientInitialized))
      ) {
        this.loggerService.log(LOG_LEVEL.Debug, functionName, `Creating all listeners.`);
        if (!this.allInitialized || this.reInitTaskRouterListeners) {
          this.createTaskRouterListeners();
        }
        if (this.enableVoiceChannel) {
          this.createVoiceListeners();
        }
        if (this.enableSMSChannel && (!this.allInitialized || this.reInitConversationListeners)) {
          this.createConversationListeners();
        }
        this.allInitialized = true;
      } else {
        this.loggerService.log(LOG_LEVEL.Debug, functionName, `Not all initialized.`);
      }
    } catch (e) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Unable to check if all initialized.`, e);
    }
  }

  /** ***********************************************************
    Task Router
  /*************************************************************/
  private async initializeTaskRouter() {
    const functionName = 'initializeTaskRouter';
    try {
      this.loggerService.log(LOG_LEVEL.Information, functionName, `Initializing task router.`);
      this.worker.on(WORKER_EVENT.Ready, this.taskRouterInitialized.bind(this));
      this.worker.on(WORKER_EVENT.Error, this.workerError.bind(this));
      this.worker.on(WORKER_EVENT.Connected, this.workerConnected.bind(this));
      this.worker.on(WORKER_EVENT.Disconnected, this.workerDisconnected.bind(this));
      this.worker.on(WORKER_EVENT.TokenExpired, this.tokenExpired.bind(this));
    } catch (e) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Unable to initialize task router.`, e);
    }
  }

  private async taskRouterInitialized() {
    const functionName = 'taskRouterInitialized';
    try {
      this.loggerService.log(LOG_LEVEL.Debug, functionName, `Task router initialized.`);
      this.taskRouterClientInitialized = true;
      this.checkIfAllInitialized();
    } catch (e) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Unable to initialize task router.`, e);
    }
  }

  private workerError(error: Error) {
    const functionName = `workerError`;
    try {
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Worker Error:`, error);
      this.previousTaskrouterState = WORKER_STATE.disconnected;
      let messageToAgent = 'SMS queue connection error. Attempting to reconnect. If this takes too long try refreshing your page.';
      if (this.enableVoiceChannel && this.enableSMSChannel) {
        messageToAgent = 'Voice and SMS queue connection error. Attempting to reconnect. If this takes too long try refreshing your page.';
      } else if (this.enableVoiceChannel) {
        messageToAgent = 'Voice queue connection error. Attempting to reconnect. If this takes too long try refreshing your page.';
      }
      sendNotification(messageToAgent, NOTIFICATION_TYPE.Error);
    } catch (e) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Unable to handle worker error.`, e);
    }
  }

  private workerConnected() {
    try {
      this.loggerService.log(LOG_LEVEL.Debug, this.workerConnected.name, `Worker socket connected.`);
      if (this.previousTaskrouterState === WORKER_STATE.disconnected) {
        this.loggerService.log(LOG_LEVEL.Debug, this.workerConnected.name, `Worker reconnected. Attempting to fetch reservations.`);
        this.worker.fetchReservations(this.fetchReservations.bind(this));
        let messageToAgent = 'SMS queue reconnected.';
        if (this.enableVoiceChannel && this.enableSMSChannel) {
          messageToAgent = 'Voice and SMS queue reconnected.';
        } else if (this.enableVoiceChannel) {
          messageToAgent = 'Voice queue reconnected.';
        }
        sendNotification(messageToAgent, NOTIFICATION_TYPE.Information);
      }
      this.previousTaskrouterState = WORKER_STATE.connected;
    } catch (e) {
      this.loggerService.log(LOG_LEVEL.Error, this.workerConnected.name, `Unable to handle worker connected.`, e);
    }
  }

  private workerDisconnected() {
    try {
      this.loggerService.log(LOG_LEVEL.Information, this.workerDisconnected.name, `Worker socket disconnected.`);
      this.previousTaskrouterState = WORKER_STATE.disconnected;
      this.reInitTaskRouterListeners = true;
    } catch (e) {
      this.loggerService.log(LOG_LEVEL.Error, this.workerDisconnected.name, `Unable to handle worker disconnected.`, e);
    }
  }

  private async tokenExpired() {
    const functionName = 'tokenExpired';
    try {
      this.loggerService.log(LOG_LEVEL.Information, functionName, `Taskrouter token expired.`);
      this.refreshTokens();
    } catch (e) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Unable to handle token expired.`, e);
    }
  }

  private async updateTaskRouterToken() {
    const functionName = 'updateTaskRouterToken';
    try {
      this.loggerService.log(LOG_LEVEL.Information, functionName, `Updating Taskrouter token.`);
      this.worker.updateToken(this.taskRouterToken);
    } catch (e) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Unable to update Taskrouter token.`, e);
    }
  }

  private async createTaskRouterListeners() {
    const functionName = 'createTaskRouterListeners';
    try {
      // TODO: listen to all events from https://www.twilio.com/docs/taskrouter/js-sdk/workspace/worker#connected
      // TODO: None of these callbacks should be set until the initilization of the worker is complete
      // https://www.twilio.com/docs/taskrouter/js-sdk/workspace/worker#ready
      this.loggerService.log(LOG_LEVEL.Debug, functionName, `Creating Taskrouter listeners.`);
      this.setInitialActivity(this.currentActivity);
      this.worker.fetchReservations(this.fetchReservations.bind(this));
      this.worker.on(WORKER_EVENT.ActivityUpdate, this.workerActivityUpdate.bind(this));
      this.worker.on(WORKER_EVENT.TaskUpdated, this.taskUpdated.bind(this));
      this.worker.on(WORKER_EVENT.Created, this.reservationCreated.bind(this));
      this.worker.on(WORKER_EVENT.Accepted, this.reservationAccepted.bind(this));
      this.worker.on(WORKER_EVENT.Rejected, this.reservationRemoved.bind(this));
      this.worker.on(WORKER_EVENT.Canceled, this.reservationRemoved.bind(this));
      this.worker.on(WORKER_EVENT.Rescinded, this.reservationRemoved.bind(this));
      this.worker.on(WORKER_EVENT.Timeout, this.reservationTimedOut.bind(this));
      this.reInitTaskRouterListeners = false;
    } catch (e) {
      sendNotification(`Failed creating Taskrouter listeners`, NOTIFICATION_TYPE.Error);
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Unable to create Taskrouter listeners.`, e);
    }
  }

  private async workerActivityUpdate(worker) {
    try {
      this.loggerService.log(LOG_LEVEL.Debug, this.workerActivityUpdate.name, `Worker activity updated. Activity SID: ${worker.activitySid}`);
      if (this.currentActivity !== worker.activitySid) {
        this.loggerService.log(LOG_LEVEL.Debug, this.workerActivityUpdate.name, `Worker activity changed.`, worker.activitySid);
        this.currentActivity = worker.activitySid;
        this.activity$.next(worker.activitySid);
      } else {
        this.loggerService.log(LOG_LEVEL.Debug, this.workerActivityUpdate.name, `Worker set to current activity`);
      }
    } catch (e) {
      this.loggerService.log(LOG_LEVEL.Error, this.workerActivityUpdate.name, `Unable to update worker activity.`, e);
    }
  }

  public async taskUpdated(task: ITask) {
    const functionName = `taskUpdated`;
    try {
      this.loggerService.log(LOG_LEVEL.Debug, functionName, `Task update event recieved. TaskSid: ${task.sid}`);
      this.loggerService.log(LOG_LEVEL.Trace, functionName, `Updated task.`, task);
    } catch (error) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Unable to update task.`, error);
    }
  }

  private async reservationCreated(reservation: IReservation) {
    const functionName = 'reservationCreated';
    try {
      this.loggerService.log(LOG_LEVEL.Debug, functionName, `Reservation created. ReservationSid: ${reservation.sid}`);
      const devicesBusy = await this.checkDevices('busy');
      const isOutbound = this.reservationIsOutbound(reservation);
      const isVoice = this.reservationIsVoice(reservation);
      if (!this.checkIfTaskIsValid(reservation)) {
        this.loggerService.log(LOG_LEVEL.Error, functionName, `Recieved an invalid SMS task.`, reservation);
        await reservation.accept();
      } else if (!devicesBusy) {
        this.loggerService.log(LOG_LEVEL.Debug, functionName, `Devices are not busy.`);
        if (isVoice) {
          this.voiceReservationCreated(reservation, isOutbound);
        } else {
          this.smsReservationCreated(reservation, isOutbound);
        }
        if (!isOutbound) {
          this.playRinger(isVoice);
        }
      } else if (devicesBusy) {
        this.loggerService.log(LOG_LEVEL.Debug, functionName, `Devices are busy. Rejecting new task.`);
        reservation.reject();
      }
    } catch (e) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Unable to handle created reservation.`, e);
    }
  }

  private async voiceReservationCreated(reservation: IReservation, isOutbound = false) {
    const functionName = 'voiceReservationCreated';
    try {
      this.loggerService.log(LOG_LEVEL.Debug, functionName, `Reservation created for`, reservation);
      if (isOutbound) {
        this.loggerService.log(LOG_LEVEL.Debug, functionName, `Outbound voice call found. Task Sid: ${reservation.taskSid}`);
        this.acceptOutbound(reservation);
      } else {
        this.loggerService.log(LOG_LEVEL.Debug, functionName, `Inbound voice call found. Task Sid: ${reservation.taskSid}`);
        if (reservation.task.attributes['outbound']) {
          if (!this.checkDevices('busy')) {
            await reservation.accept(async (error) => {
              if (error) {
                throw error;
              }
            });
          }
        } else {
          const interaction: ITwilioInteraction = {
            channel: CHANNELS.Phone,
            taskSid: reservation.taskSid,
            reservationSid: reservation.sid,
            reservation,
            parties: [],
            connection: null,
            isHeld: false,
            isBlindTransfering: false,
            isWarmTransfering: false
          };
          this.addTwilioInteraction(interaction);
          this.generateSetInteraction(interaction, INTERACTION_STATES.Alerting);
        }
      }
    } catch (e) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Failed to handle created voice task.`, e);
    }
  }

  private async smsReservationCreated(reservation: IReservation, isOutbound = false) {
    const functionName = `smsreservationCreated`;
    try {
      this.loggerService.log(LOG_LEVEL.Information, functionName, `Reservation created for`, reservation);
      const interaction = await this.createTwilioInteraction(reservation);
      this.addTwilioInteraction(interaction);
      this.generateSetInteraction(interaction, isOutbound ? INTERACTION_STATES.Initiated : INTERACTION_STATES.Alerting);
      if (isOutbound) {
        await reservation.accept();
      }
      this.loggerService.log(LOG_LEVEL.Information, functionName, `Finished handling created SMS reservation.`, reservation);
    } catch (e) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Error handling sms reservation.`, e);
    }
  }

  private async reservationAccepted(reservation: IReservation) {
    const functionName = 'reservationAccepted';
    try {
      this.loggerService.log(LOG_LEVEL.Information, functionName, `Reservation accepted for`, reservation);
      if (!this.checkIfTaskIsValid(reservation)) {
        this.workerCompleteTask(reservation.taskSid);
        this.deleteTwilioInteraction(reservation.taskSid, reservation.sid);
        return;
      }

      const isOutbound = this.reservationIsOutbound(reservation);
      if (isOutbound) {
        this.loggerService.log(LOG_LEVEL.Debug, functionName, `Returning agent to previous presence for outbound.`);
        await this.returnToPreviousPresence();
      } else {
        this.pauseRinger();
      }

      const isVoice = this.reservationIsVoice(reservation);
      if (isVoice) {
        this.voiceReservationAccepted(reservation);
      } else {
        this.smsReservationAccepted(reservation);
      }
    } catch (e) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Unable to handle accepted reservation.`, e);
    }
  }

  private voiceReservationAccepted(reservation: IReservation) {
    const functionName = `voiceReservationAccepted`;
    try {
      this.loggerService.log(LOG_LEVEL.Information, functionName, `Reservation accepted for`, reservation);
      const twilioInteraction = this.getTwilioInteractionByTaskSid(reservation.taskSid);
      this.nextTwilioInteraction(twilioInteraction);
      this.generateSetInteraction(twilioInteraction.interaction, INTERACTION_STATES.Connected);
    } catch (e) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Unable to handle accepted reservation.`, e);
    }
  }

  private async smsReservationAccepted(reservation: IReservation) {
    const functionName = `smsReservationAccepted`;
    try {
      this.loggerService.log(LOG_LEVEL.Debug, functionName, `Reservation accepted. TaskSid:`, reservation.taskSid);
      this.loggerService.log(LOG_LEVEL.Trace, functionName, `Reservation accepted. Reservation: `, reservation);
      const conversationSid = await this.checkOrCreateConversation(reservation);
      await this.joinConversation(conversationSid);
      const messages = await this.getMessagesFromConversation(conversationSid);
      const twilioInteraction = this.getTwilioInteractionByTaskSid(reservation.taskSid);
      twilioInteraction.interaction.chat = {
        channel: conversationSid,
        messages: messages
      };
      twilioInteraction.interaction.reservation = reservation;
      this.nextTwilioInteraction(twilioInteraction);
      this.generateSetInteraction(twilioInteraction.interaction, INTERACTION_STATES.Connected);
    } catch (e) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Unable to handle accepted reservation.`, { error: e });
    }
  }

  private reservationTimedOut(reservation: IReservation) {
    const functionName = `reservationTimedOut`;
    try {
      this.loggerService.log(LOG_LEVEL.Trace, functionName, `Reservation timed out for`, reservation);
      // TODO: Implement a way to determine the next action for an agent to take when a timeout occurs.
      // sendNotification(`Task not accepted in time.`, 0);
      this.reservationRemoved(reservation);
    } catch (e) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Error in reservation timeout.`, e);
    }
  }

  private reservationRemoved(reservation: IReservation) {
    const functionName = `reservationRemoved`;
    try {
      this.loggerService.log(LOG_LEVEL.Debug, functionName, `Reservation removed.`, { reservationSid: reservation.sid, taskSid: reservation.taskSid });
      this.loggerService.log(LOG_LEVEL.Trace, functionName, `Reservation removed for`, reservation);
      this.pauseRinger();
      if (reservation.task.attributes.type.toUpperCase() === CHANNELS.Phone.toUpperCase()) {
        this.voiceReservationRemoved(reservation);
      } else if (reservation.task.attributes.type.toUpperCase() === CHANNELS.SMS) {
        this.smsReservationRemoved(reservation);
      }
    } catch (e) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Error in reservation removal.`, e);
    }
  }

  private voiceReservationRemoved(reservation: IReservation) {
    const functionName = `voiceReservationRemoved`;
    try {
      this.loggerService.log(LOG_LEVEL.Debug, functionName, `Reservation removed for`, reservation);
      this.deleteTwilioInteraction(reservation.task.sid, reservation.sid);
    } catch (e) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Error in reservation removal.`, e);
    }
  }

  private async smsReservationRemoved(reservation: IReservation) {
    const functionName = `smsReservationRemoved`;
    try {
      this.loggerService.log(LOG_LEVEL.Debug, functionName, `Reservation removed.`, { reservationSid: reservation.sid, taskSid: reservation.taskSid });
      this.loggerService.log(LOG_LEVEL.Trace, functionName, `Reservation removed for`, reservation);
      this.deleteTwilioInteraction(reservation.task.sid, reservation.sid);
    } catch (e) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Error in reservation removal.`, e);
    }
  }

  public async workerCompleteTask(taskSid: string) {
    const functionName = `workerCompleteTask`;
    try {
      this.loggerService.log(LOG_LEVEL.Debug, functionName, `Completing task for worker. TaskSid: ${taskSid}`);
      const twilioInteraction = this.getTwilioInteractionByTaskSid(taskSid);
      this.loggerService.log(LOG_LEVEL.Trace, functionName, `Completing task for worker. TwilioInteraction: `, twilioInteraction.interaction);
      if (twilioInteraction?.interaction?.reservation?.task) {
        this.loggerService.log(LOG_LEVEL.Debug, functionName, `TwilioInteraction has a valid task to complete.`, { taskSid: taskSid, interaction: twilioInteraction.interaction });
        twilioInteraction.interaction.reservation.task.complete(this.workerCompleteTaskCallback.bind(this, taskSid));
      } else {
        this.loggerService.log(LOG_LEVEL.Warning, functionName, `TwilioInteraction does not have a valid task to complete.`, { taskSid: taskSid });
        this.worker.completeTask(taskSid, this.workerCompleteTaskCallback.bind(this, taskSid));
      }
    } catch (error) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Failed to complete task.`, { error, taskSid });
    }
  }

  private async workerCompleteTaskCallback(taskSid: string, error, completedTask: ITask) {
    try {
      if (error) {
        this.loggerService.log(LOG_LEVEL.Warning, this.workerCompleteTaskCallback.name, `Trying to complete a task that isn't assigned.`, { taskSid: taskSid, error: error });
        this.worker.fetchReservations(this.completeTaskFetchReservations.bind(this, taskSid), { ReservationStatus: RESERVATION_STATE.accepted });
      } else {
        if (completedTask) {
          this.loggerService.log(LOG_LEVEL.Debug, this.workerCompleteTaskCallback.name, 'Completed Task Sid.', completedTask.sid);
          this.loggerService.log(LOG_LEVEL.Trace, this.workerCompleteTaskCallback.name, `Completed Task.`, completedTask);
          const twilioInteraction = this.getTwilioInteractionByTaskSid(completedTask.sid);
          if (twilioInteraction?.interaction?.reservation?.task) {
            this.deleteTwilioInteraction(twilioInteraction.interaction.reservation.task.sid, twilioInteraction.interaction.reservation.sid);
          } else {
            this.loggerService.log(LOG_LEVEL.Warning, this.workerCompleteTaskCallback.name, 'Attempted to delete an interaction that does not exist');
          }
        }
      }
    } catch (error) {
      this.loggerService.log(LOG_LEVEL.Error, this.workerCompleteTaskCallback.name, `Failed to complete task.`, error);
    }
  }

  private async completeTaskFetchReservations(taskSid: string, error, reservations: { data: IReservation[] }) {
    const functionName = `completeTaskFetchReservations`;
    try {
      if (error) {
        throw error;
      }
      this.loggerService.log(LOG_LEVEL.Debug, functionName, 'Checking completed reservations for a matching taskSid.', { taskSid: taskSid, reservations: reservations.data.length });
      let foundTask = false;
      if (reservations.data.length > 0) {
        this.loggerService.log(LOG_LEVEL.Debug, functionName, `Checking reservations for completed task.`, taskSid);
        for (let i = 0; i < reservations.data.length; i++) {
          if (reservations.data[i].task.sid === taskSid) {
            this.loggerService.log(LOG_LEVEL.Trace, functionName, `Found accepted reservation for task.`, reservations[i]);
            foundTask = true;
            break;
          }
        }
      }
      if (!foundTask) {
        this.loggerService.log(LOG_LEVEL.Debug, functionName, `No accepted reservation found for task.`, taskSid);
        const twilioInteraction = this.getTwilioInteractionByTaskSid(taskSid);
        this.deleteTwilioInteraction(twilioInteraction.interaction.reservation.task.sid, twilioInteraction.interaction.reservation.sid);
      }
    } catch (error) {
      sendNotification(`Failed to complete task.`, NOTIFICATION_TYPE.Error);
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Failed to fetch reservations.`, error);
    }
  }

  public async wrapUpTask(taskSid: string) {
    const functionName = `wrapUpTask`;
    try {
      this.loggerService.log(LOG_LEVEL.Debug, functionName, `Wrapping up task. TaskSid: ${taskSid}`);
      await this.http
        .post('wrapUpTask', {
          AccountSid: this.accountSid,
          AuthToken: this.authToken,
          WorkSpaceSid: this.workSpaceSid,
          TaskSid: taskSid,
          Reason: 'Call Ended'
        })
        .subscribe(async (response) => {});
    } catch (e) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Failed to wrap up task.`, e);
    }
  }

  public reservationIsOutbound(reservation: IReservation): boolean {
    const functionName = `reservationIsOutbound`;
    try {
      this.loggerService.log(LOG_LEVEL.Debug, functionName, `Checking if reservation is outbound. TaskSid: ${reservation.task.sid}`);
      this.loggerService.log(LOG_LEVEL.Trace, functionName, `Reservation task:`, reservation.task);
      // TODO: Log the evaluation of the outbound attribute.
      const isOutbound = reservation.task.attributes.outbound !== undefined && reservation.task.attributes.outbound.toString() === 'true';
      this.loggerService.log(LOG_LEVEL.Debug, functionName, `Task is ${isOutbound ? 'Outbound' : 'Inbound'}`, { isOutbound, taskSid: reservation.task.sid });
      return isOutbound;
    } catch (error) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Unable to determine if reservation is outbound.`, error);
    }
  }

  public reservationIsVoice(reservation: IReservation): boolean {
    const functionName = `reservationIsVoice`;
    try {
      this.loggerService.log(LOG_LEVEL.Debug, functionName, `Checking if reservation is voice. TaskSid: ${reservation.task.sid}`);
      this.loggerService.log(LOG_LEVEL.Trace, functionName, `Reservation task:`, reservation.task);
      const isVoice = reservation.task.attributes.type.toUpperCase() === CHANNELS.Phone.toUpperCase();
      this.loggerService.log(LOG_LEVEL.Debug, functionName, `Reservation is voice.`, { isVoice, taskSid: reservation.task.sid });
      return isVoice;
    } catch (error) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Unable to determine if reservation is voice.`, error);
    }
  }

  public async createOutboundTask(conferenceName: string, to: string, type: string = CHANNELS.Phone) {
    const functionName = `createOutboundTask`;
    try {
      const currentTime = new Date().getTime();
      if (!this.checkIfPhoneNumberIsValid(to)) {
        this.loggerService.log(LOG_LEVEL.Information, functionName, `Outbound task not sent. Invalid phone number.`, { to, type });
        sendNotification(`Invalid phone number.`, NOTIFICATION_TYPE.Error);
        return;
      } else if (currentTime - this.lastOutboundNumber.timestamp < this.outboundTimeBuffer) {
        this.loggerService.log(LOG_LEVEL.Information, functionName, `Outbound task not sent. Waiting for ${this.outboundTimeBuffer / 1000} seconds.`, { to, type });
        return;
      } else if (Object.keys(this.twilioInteractions).length > 0) {
        const interactionKeys = Object.keys(this.twilioInteractions);
        for (let i = 0; i < interactionKeys.length; i++) {
          const interaction = this.twilioInteractions[interactionKeys[i]];
          if (interaction.interaction.reservation.task.attributes['from'] === to) {
            this.loggerService.log(LOG_LEVEL.Information, functionName, `Outbound task not sent. Outbound task already recieved for this number.`, { to, type });
            sendNotification(`Outbound ${type === CHANNELS.Phone ? 'Call' : 'SMS'} cannot be started. Please complete your current ${type === CHANNELS.Phone ? 'Call' : 'SMS'}.`, NOTIFICATION_TYPE.Error);
            return;
          }
        }
      } else {
        this.loggerService.log(LOG_LEVEL.Information, functionName, `Creating Outbound task.`, { to, type });
        this.lastOutboundNumber.phoneNumber = to;
        this.lastOutboundNumber.timestamp = currentTime;
        if (this.outboundPresenceEnabled === true && this.currentActivity !== this.outboundPresenceOnlySid) {
          this.loggerService.log(LOG_LEVEL.Information, functionName, `Setting presence to outbound only.`, { currentPresence: this.currentActivity });
          this.previousActivity = this.currentActivity;
          await this.setActivity(this.outboundPresenceOnlySid);
          this.currentActivity = this.outboundPresenceOnlySid;
        }
        // TODO: add timeout to sendNotification
        // sendNotification(`Initiating ${type === CHANNELS.Phone ? 'Call' : 'SMS'} to ${to}`, NOTIFICATION_TYPE.Information);
        this.loggerService.log(LOG_LEVEL.Trace, functionName, `Creating outbound task.`, { to: to, type: type, from: this.username, outboundNumber: this.outboundNumber });
        const task = await this.http
          .post('OutboundTask', {
            AccountSid: this.accountSid,
            AuthToken: this.authToken,
            WorkflowSid: this.directWorkflowSid,
            WorkSpaceSid: this.workSpaceSid,
            to: to,
            from: this.username,
            OutboundNumber: this.outboundNumber,
            FriendlyName: conferenceName, // not needed for SMS
            ConferenceSid: '',
            TaskType: type
          })
          .toPromise();
        if (task['error']) {
          throw task['error'];
        }
        this.loggerService.log(LOG_LEVEL.Debug, functionName, `Created outbound task.`, { task });
        return task['taskSid'];
      }
    } catch (e) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Failed to create outbound task.`, e);
      sendNotification(`Failed to initiate outbound.`, NOTIFICATION_TYPE.Error);
    }
  }

  // eslint-disable-next-line max-statements
  public async fetchReservations(error, reservations) {
    try {
      this.loggerService.log(LOG_LEVEL.Debug, this.fetchReservations.name, `Fetching reservations.`);
      // TODO: clear interactions list and UI before creating new interactions
      // TODO: check if reservation is already in list before adding
      if (!error) {
        if (Object.keys(this.twilioInteractions).length > 0) {
          this.loggerService.log(LOG_LEVEL.Debug, this.fetchReservations.name, `Clearing interactions.`, { interactions: this.twilioInteractions });
          for (const key in this.twilioInteractions) {
            if (key) {
              let reservationExists = false;

              const reservationsKeys = Object.keys(reservations.data);
              for (let i = 0; i < reservationsKeys.length; i++) {
                const reservation = reservations.data[reservationsKeys[i]];
                if (this.twilioInteractions[key].interaction.reservation.sid === reservation.sid) {
                  this.loggerService.log(LOG_LEVEL.Debug, this.fetchReservations.name, `Reservation found for interaction. Keeping interaction `, { taskSid: key, interaction: this.twilioInteractions[key].interaction });
                  // TODO: check if reservation is active
                  reservationExists = true;
                  break;
                }
              }

              if (!reservationExists) {
                this.loggerService.log(LOG_LEVEL.Debug, this.fetchReservations.name, `No reservation found for interaction. Removing interaction `, { taskSid: key, interaction: this.twilioInteractions[key] });
                this.deleteTwilioInteraction(this.twilioInteractions[key].interaction.reservation.task.sid, this.twilioInteractions[key].interaction.reservation.sid);
              }
            }
          }
        }

        const reservationsList = reservations.data;
        const taskReservationMap: { [taskSid: string]: IReservation[] } = {};
        for (let i = 0; i < reservationsList.length; i++) {
          const reservation = reservationsList[i];
          // Note: This is grouping all reservations by task sid
          if (taskReservationMap[reservation.taskSid]) {
            taskReservationMap[reservation.taskSid].push(reservation);
          } else {
            taskReservationMap[reservation.taskSid] = [reservation];
          }
        }

        this.loggerService.log(LOG_LEVEL.Trace, this.fetchReservations.name, `Task reservation map.`, { taskReservationMap });
        const taskSids = Object.keys(taskReservationMap);
        for (let i = 0; i < taskSids.length; i++) {
          const task = taskReservationMap[taskSids[i]];
          this.loggerService.log(LOG_LEVEL.Loop, this.fetchReservations.name, `Checking task reservations.`, { taskSid: taskSids[i], task });
          if (task.length > 1) {
            const nonRejects: number[] = [];
            for (let j = 0; j < task.length; j++) {
              // Note: any tasks pushed to nonRejects are active or pending tasks
              const reservation = task[j];
              this.loggerService.log(LOG_LEVEL.Loop, this.fetchReservations.name, `Checking reservation status.`, {
                reservationSid: reservation.sid,
                taskSid: reservation.task.sid,
                reservationState: reservation.reservationStatus,
                taskState: reservation.task.assignmentStatus
              });
              if (
                (reservation.reservationStatus === RESERVATION_STATE.pending || reservation.reservationStatus === RESERVATION_STATE.accepted) &&
                (reservation.task.assignmentStatus === TASK_STATE.reserved || reservation.task.assignmentStatus === TASK_STATE.assigned)
              ) {
                this.loggerService.log(LOG_LEVEL.Debug, this.fetchReservations.name, `Valid reservation.`, {
                  reservationSid: task[j].sid,
                  taskSid: task[j].taskSid,
                  reservationState: task[j].reservationStatus,
                  taskState: task[j].task.assignmentStatus
                });
                nonRejects.push(j);
              }
            }
            if (nonRejects.length > 0) {
              for (let k = 0; k < nonRejects.length; k++) {
                this.loggerService.log(LOG_LEVEL.Debug, this.fetchReservations.name, `Handling valid reservation.`, { reservationSid: task[nonRejects[k]].sid });
                await this.handleReservation(task[nonRejects[k]]);
              }
            } else {
              // TODO: Keep list of tasks which sent a disconnect in local storage so that every refresh doesn't send a disconnect
              this.loggerService.log(LOG_LEVEL.Debug, this.fetchReservations.name, `No valid reservations.`, { reservationSid: task[0].sid });
              await this.handleReservation(task[0]);
            }
          } else {
            // TODO: Handle single reservation
            this.loggerService.log(LOG_LEVEL.Debug, this.fetchReservations.name, `Single reservation.`, { reservationSid: task[0].sid });
            await this.handleReservation(task[0]);
          }
        }
        // TODO: leave all conversations without active tasks
      } else {
        this.loggerService.log(LOG_LEVEL.Error, this.fetchReservations.name, `Failed to fetch reservations.`, error);
        sendNotification('Failed to fetch reservations.', NOTIFICATION_TYPE.Error);
      }
    } catch (error) {
      this.loggerService.log(LOG_LEVEL.Error, this.fetchReservations.name, `Failed to fetch reservations.`, error);
    }
  }

  private async handleReservation(reservation: IReservation) {
    try {
      this.loggerService.log(LOG_LEVEL.Debug, this.handleReservation.name, `New Reservation found`, { taskSid: reservation.task.sid, taskState: reservation.task.assignmentStatus, reservationSid: reservation.sid, reservationState: reservation.reservationStatus });
      const interaction = await this.createTwilioInteraction(reservation);
      await this.addTwilioInteraction(interaction);
      switch (reservation.reservationStatus) {
        case RESERVATION_STATE.pending:
          if (reservation.task.assignmentStatus === TASK_STATE.assigned || reservation.task.assignmentStatus === TASK_STATE.reserved) {
            await this.reservationCreated(reservation);
          } else {
            this.loggerService.log(LOG_LEVEL.Warning, this.handleReservation.name, 'Reservation and Task state mismatch for pending reservation.', { reservationState: reservation.reservationStatus, taskState: reservation.task.assignmentStatus });
            await this.reservationRemoved(reservation);
          }
          break;
        case RESERVATION_STATE.accepted:
          if (reservation.task.assignmentStatus === TASK_STATE.assigned || reservation.task.assignmentStatus === TASK_STATE.reserved) {
            await this.reservationAccepted(reservation);
          } else {
            this.loggerService.log(LOG_LEVEL.Warning, this.handleReservation.name, 'Reservation and Task state mismatch for accepted reservation.', { reservationState: reservation.reservationStatus, taskState: reservation.task.assignmentStatus });
            await this.reservationRemoved(reservation);
          }
          break;
        case RESERVATION_STATE.rejected:
        case RESERVATION_STATE.canceled:
        case RESERVATION_STATE.timeout:
        case RESERVATION_STATE.completed:
          await this.reservationRemoved(reservation);
          break;
        default:
          this.loggerService.log(LOG_LEVEL.Critical, this.handleReservation.name, `Unknown reservation status.`, reservation.reservationStatus);
          await this.resetConnection(reservation);
          break;
      }
    } catch (error) {
      this.loggerService.log(LOG_LEVEL.Error, this.handleReservation.name, `Failed to fetch reservations.`, error);
    }
  }

  private checkIfTaskIsValid(reservation: IReservation): boolean {
    const functionName = `checkIfTaskIsValid`;
    try {
      this.loggerService.log(LOG_LEVEL.Debug, functionName, `Checking if task is valid.`, { taskSid: reservation.task.sid });
      this.loggerService.log(LOG_LEVEL.Trace, functionName, `Reservation:`, reservation);
      if (
        !(
          this.checkIfPhoneNumberIsValid(reservation.task.attributes.from) &&
          ((reservation.task.attributes.to && this.checkIfPhoneNumberIsValid(reservation.task.attributes.to)) || (reservation.task.attributes.originNumber && this.checkIfPhoneNumberIsValid(reservation.task.attributes.originNumber)))
        )
      ) {
        // To/OriginNumber and From are not valid phone numbers
        this.loggerService.log(LOG_LEVEL.Error, functionName, `Recieved an invalid SMS task.`, reservation.task.sid);
        return false;
      } else {
        this.loggerService.log(LOG_LEVEL.Trace, functionName, `Task is valid.`, reservation);
        return true;
      }
    } catch (error) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Unable to determine if task is valid.`, error);
    }
  }

  private checkIfPhoneNumberIsValid(phoneNumber: string): boolean {
    const functionName = `checkIfPhoneNumberIsValid`;
    try {
      this.loggerService.log(LOG_LEVEL.Debug, functionName, `Checking if phone number is valid.`, phoneNumber);
      if (!/^([+()\- 0-9]*)$/.test(phoneNumber)) {
        this.loggerService.log(LOG_LEVEL.Error, functionName, `Recieved an invalid phone number.`, phoneNumber);
        return false;
      } else {
        this.loggerService.log(LOG_LEVEL.Trace, functionName, `Phone number is valid.`, phoneNumber);
        return true;
      }
    } catch (error) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Unable to determine if phone number is valid.`, error);
    }
  }

  /** ***********************************************************
    Phone
  /*************************************************************/
  public async addDevices() {
    const functionName = `addDevices`;
    try {
      this.loggerService.log(LOG_LEVEL.Debug, functionName, `Adding devices for voice.`);
      for (let device of this.devices) {
        if (device !== undefined && device.status() !== 'busy') {
          device = undefined; // makes all device null
        }
      }
      // Initializes all of the devices for handling calls
      for (let i = 0; i < this.devices.length; i++) {
        // Iterates over the array of devices and creates devices
        if (this.devices[i] === undefined) {
          try {
            this.devices[i] = await new Device(this.voiceToken, {
              // codecPreferences: ['opus', 'pcmu']
            });
          } catch (error) {
            this.loggerService.log(LOG_LEVEL.Error, functionName, `Failed to create device number ${i}.`, error);
          }
        }
      }
      if (this.enableVoiceChannel) {
        // TODO: This is a redundant check. Remove it.
        // TODO: Move createVoiceListeners out of the for loop for creating devices
        await this.initializeDevices();
      }
    } catch (e) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Failed to add devices.`, e);
    }
  }

  private async initializeDevices() {
    const functionName = `loopDevices`;
    try {
      for (let i = 0; i < this.devices.length; i++) {
        this.loggerService.log(LOG_LEVEL.Debug, functionName, `Initializing voice listeners for device ${i}`);
        this.devices[i].on('ready', this.deviceReady.bind(this));
      }
    } catch (e) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Failed to loop devices.`, e);
    }
  }

  public async checkDevices(state: string) {
    if (this.enableVoiceChannel) {
      for (const device of this.devices) {
        // Check if there are any devices in the given state
        if (device.status() === state) {
          return true;
        }
      }
    }
    return false;
  }

  public async holdDevices() {
    const functionName = `holdDevices`;
    try {
      this.loggerService.log(LOG_LEVEL.Debug, functionName, `Holding devices for voice.`);
      for (const device of this.devices) {
        // Check if all devices are busy
        if (device.status() === 'busy') {
          // If the device is busy it gets put on hold when making an outbound call
          const twilioObject = await this.getTwilioInteractionByConnection(device.activeConnection());
          if (!twilioObject.interaction.isHeld) {
            await this.hold(twilioObject.interaction, true);
          }
        }
      }
    } catch (e) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Unable to hold devices.`, e);
    }
  }

  public async refreshOfflineDevices() {
    // TODO: make this function refresh instead of recreating the devices
    const functionName = `refreshOfflineDevices`;
    try {
      this.loggerService.log(LOG_LEVEL.Debug, functionName, `Refreshing offline devices.`);
      for (let i = 0; i < this.devices.length; i++) {
        if (this.devices[i].status() === 'offline') {
          try {
            this.devices[i].setup(this.voiceToken, {
              // codecPreferences: ['opus', 'pcmu']
            });
          } catch (error) {
            this.loggerService.log(LOG_LEVEL.Error, functionName, `Failed to create device number ${i}.`, error);
          }
        }
      }
    } catch (e) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Failed to refresh devices.`, e);
    }
  }

  private async deviceReady(device: Device) {
    const functionName = `deviceReady`;
    try {
      this.loggerService.log(LOG_LEVEL.Loop, functionName, `Another device is initialized.`, { device: device, voiceDevicesInitialized: this.voiceDevicesInitialized, devices: this.devices });
      this.voiceDevicesInitialized++;
      if (this.voiceDevicesInitialized >= this.devices.length) {
        this.loggerService.log(LOG_LEVEL.Debug, functionName, `All devices are ready.`);
        this.voiceClientInitialized = true;
        this.checkIfAllInitialized();
      }
    } catch (e) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Failed to initialize voice listeners.`, e);
    }
  }

  private async createVoiceListeners() {
    // TODO: Iterate through devices instead of taking index
    // TODO: None of these callbacks should be set until the devices are initialized
    // TODO: move all of the callbacks into their own functions
    for (let i = 0; i < this.devices.length; i++) {
      this.devices[i].on('error', (error) => {
        this.loggerService.log(LOG_LEVEL.Error, `createVoiceListeners`, `Failed to create voice listener.`, error);
        if (error.message !== 'JWT Token Expired') {
          sendNotification('Failed to create voice listener.', NOTIFICATION_TYPE.Error);
        }
      });
      this.devices[i].on('connect', async (connection) => {
        await connection.on('mute', async () => {
          await this.nextTwilioInteraction(await this.getTwilioInteractionByConnection(connection));
        });
        await this.setConnection(connection);
      });
      this.devices[i].on('disconnect', async (connection) => {
        const twilioObject = await this.getTwilioInteractionByConnection(connection);
        if (twilioObject.interaction.channel === 'Phone') {
          this.wrapup(twilioObject.interaction, true);
        }
        if (twilioObject.interaction.channel !== 'Phone') {
          this.deleteTwilioInteraction(twilioObject.interaction.taskSid, twilioObject.interaction.reservationSid);
        }
      });

      this.devices[i].on('offline', async (device) => {
        await this.refreshTokens();
      });

      if (i === 0) {
        // Only the first device is listening for incoming calls
        this.devices[0].on('incoming', async (connection) => {
          await this.setConnection(connection);
          connection.accept();
        });
      }
    }
  }

  private async setConnection(connection: any, reservation?: IReservation) {
    let twilioObject = await this.getTwilioInteractionByConnection(connection);
    if (!twilioObject) {
      connection.parameters.From = this.outboundTo;
      connection.parameters.TaskSid = reservation.task.sid;
      const interaction: ITwilioInteraction = {
        channel: CHANNELS.Phone,
        connection,
        taskSid: reservation.task.sid,
        reservationSid: reservation.sid,
        parties: [],
        reservation: reservation,
        isHeld: false,
        isBlindTransfering: false,
        isWarmTransfering: false
      };
      twilioObject = await this.addTwilioInteraction(interaction);
      this.generateSetInteraction(interaction, INTERACTION_STATES.Alerting);
    } else {
      twilioObject.interaction.connection = connection;
    }
    this.nextTwilioInteraction(twilioObject);
  }

  private async resetConnection(reservation: IReservation) {
    let channel;
    let channelType;
    if (reservation.task.attributes['channel'] === 'phone') {
      channel = CHANNELS.Phone;
      channelType = CHANNEL_TYPES.Telephony;
    } else if (reservation.task.attributes['channel'] === 'chat') {
      channel = CHANNELS.Chat;
      channelType = CHANNEL_TYPES.Chat;
    } else {
      channel = CHANNELS.SMS;
      channelType = CHANNEL_TYPES.SMS;
    }

    let connectionDirection;
    let direction;
    if (reservation.task.attributes['outbound'] && reservation.task.attributes['outbound'].toString() === 'true') {
      connectionDirection = CONNECTION_DIRECTION.OUTBOUND;
      direction = INTERACTION_DIRECTION_TYPES.Outbound;
    } else {
      connectionDirection = CONNECTION_DIRECTION.INCOMING;
      direction = INTERACTION_DIRECTION_TYPES.Inbound;
    }

    const connection: IConnection = {
      direction: connectionDirection,
      parameters: {
        AccountSid: this.accountSid,
        CallSid: reservation.task.attributes['conference']['sid'],
        TaskSid: reservation.task.sid,
        From: reservation.task.attributes['from'],
        To: reservation.task.attributes['to'],
        customParameters: null
      }
    };

    const interaction: ITwilioInteraction = {
      channel: channel,
      connection,
      taskSid: reservation.task.sid,
      reservationSid: reservation.sid,
      parties: [],
      reservation: reservation,
      isHeld: false,
      isBlindTransfering: false,
      isWarmTransfering: false,
      isWrapup: true
    };
    const twilioInteraction = await this.addTwilioInteraction(interaction);
    this.generateSetInteraction(twilioInteraction.interaction, INTERACTION_STATES.Disconnected);
    this.nextTwilioInteraction(twilioInteraction);
    this.wrapup(interaction, true);
  }

  private async getConferenceSid(friendlyName: string) {
    try {
      const conference = await this.http
        .post('GetConferenceSid', {
          AccountSid: this.accountSid,
          AuthToken: this.authToken,
          FriendlyName: friendlyName
        })
        .toPromise();
      return conference;
    } catch (e) {
      this.loggerService.log(LOG_LEVEL.Error, `getConferenceSid`, `Failed to get conference sid.`, e);
    }
  }

  // private async phoneReservationCreated(reservation: IReservation) {
  //   try {
  //     if (reservation.reservationStatus !== 'canceled') {
  //       if (reservation.task.attributes['outbound']) {
  //         // because you can initiate an outbound while on another call we must automatically accept outbound tasks
  //         if (!this.checkDevices('busy')) {
  //           await reservation.accept(async (error) => {
  //             if (error) {
  //               throw error;
  //             }
  //           });
  //         }
  //       } else {
  //         const interaction: ITwilioInteraction = {
  //           channel: CHANNELS.Phone,
  //           taskSid: reservation.taskSid,
  //           reservation,
  //           parties: [],
  //           connection: null,
  //           isHeld: false,
  //           isBlindTransfering: false,
  //           isWarmTransfering: false
  //         };
  //         await this.addTwilioInteraction(interaction);
  //         this.generateSetInteraction(interaction, INTERACTION_STATES.Alerting);
  //         if (this.incomingPhoneRinger) {
  //           this.incomingPhoneRinger.play();
  //         }
  //       }
  //     }
  //   } catch (error) {
  //     this.loggerService.logger.logError(
  //       'Error accepting reservation: ' + error
  //     );
  //   }
  // }

  private async updateParties(twilioInteraction: ITwilioInteraction) {
    const functionName = 'updateParties';
    try {
      let taskSid = '';
      if (twilioInteraction.taskSid !== null) {
        taskSid = twilioInteraction.taskSid;
      } else {
        taskSid = twilioInteraction.reservation.task.sid;
      }
      let attributes: any;
      if (taskSid !== null) {
        attributes = await this.http
          .post('GetParties', {
            AccountSid: this.accountSid,
            AuthToken: this.authToken,
            WorkspaceSid: this.workSpaceSid,
            TaskSid: taskSid
          })
          .toPromise();
      } else {
        return;
      }
      if (JSON.parse(attributes['attributes'])['parties'] != null && twilioInteraction.parties != null && JSON.parse(attributes['attributes'])['parties'].length < twilioInteraction.parties.length) {
        clearInterval(this.warmTransferCheckInterval);
        twilioInteraction.isWarmTransfering = null;
        twilioInteraction.confirmingWarmTransfer = null;
        twilioInteraction.incomingWarmTransfer = null;
      } else if (JSON.parse(attributes['attributes'])['parties'] != null && twilioInteraction.parties != null && JSON.parse(attributes['attributes'])['parties'].length > twilioInteraction.parties.length) {
        clearInterval(this.warmTransferCheckInterval);
      }
      twilioInteraction.reservation.task.attributes.conference = JSON.parse(attributes['attributes'])['conference'];
      twilioInteraction.parties = JSON.parse(attributes['attributes'])['parties'];
      if (twilioInteraction.parties != null && twilioInteraction.parties.length < 2) {
        let response = await this.http
          .post('GetConferenceSid', {
            AccountSid: this.accountSid,
            AuthToken: this.authToken,
            friendlyName: twilioInteraction.connection['customParameters'].get('friendlyName')
          })
          .toPromise();
        let conferenceSid = response['conferenceSid'];
        if (conferenceSid === undefined) {
          response = await this.http
            .post('GetConferenceFromTask', {
              AccountSid: this.accountSid,
              AuthToken: this.authToken,
              WorkSpaceSid: this.workSpaceSid,
              TaskSid: twilioInteraction.reservation.task.sid
            })
            .toPromise();
          conferenceSid = response['conferenceSid'];
        }
        await this.endConferencOnExit(conferenceSid, true);
      }
    } catch (e) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Failed to update parties.`, e);
    }
  }

  public async endConferencOnExit(conferenceSid: string, endConferenceOnExit: boolean) {
    await this.http
      .post('EndConferenceOnExit', {
        accountSid: this.accountSid,
        authToken: this.authToken,
        conferenceSid: conferenceSid,
        endConferenceOnExit: endConferenceOnExit
      })
      .toPromise();
  }

  public async getCallInformation(callSid: string) {
    const call = await this.http
      .post('GetCaller', {
        AccountSid: this.accountSid,
        AuthToken: this.authToken,
        CallSid: callSid
      })
      .toPromise();
    return call['call'];
  }

  public async acceptConference(twilioInteraction: ITwilioInteraction, from: string) {
    twilioInteraction.reservation.accept(async (error, reservation) => {
      if (error) {
        throw error;
      }
    });
    await this.http
      .post('acceptConference', {
        accountSid: this.accountSid,
        authToken: this.authToken,
        workSpaceSid: this.workSpaceSid,
        taskSid: twilioInteraction.reservation.task.sid,
        conferenceSid: twilioInteraction.reservation.task.attributes.conference['sid'],
        workerFriendlyName: 'client:' + this.username,
        from: from
      })
      .toPromise();
    if (this.incomingPhoneRinger && !this.incomingPhoneRinger.paused) {
      this.incomingPhoneRinger.pause();
    }
  }

  public async acceptOutbound(reservation: IReservation) {
    const functionName = `acceptOutbond`;
    try {
      this.loggerService.log(LOG_LEVEL.Information, functionName, `Accepting outbound voice task`, reservation.task.sid);
      this.loggerService.log(LOG_LEVEL.Trace, functionName, `Reservation`, reservation);
      await reservation.accept();
      for (const device of this.devices) {
        // Check for a ready device, if there is one connect to it
        if (device) {
          if (device.status() === 'ready') {
            const friendlyNamePreHash = Date.now() + reservation.task.attributes.from;
            const friendlyName256 = sha256(friendlyNamePreHash);
            const friendlyName64 = Base64.stringify(friendlyName256);
            let conferenceSid;
            let attempts = 0;
            this.conferenceFriendlyName = friendlyName64.replace(/\//g, ''); // Removes forward slashes from conferenceName

            const connection = await device.connect({
              friendlyName: this.conferenceFriendlyName,
              phone: reservation.task.attributes.from
            });

            while (conferenceSid === undefined && attempts < 20) {
              await this.setTimeoutAsPromise(500);
              conferenceSid = await this.getConferenceSid(this.conferenceFriendlyName);
              attempts++;
            }

            await this.http
              .post('AddConferenceSid', {
                AccountSid: this.accountSid,
                AuthToken: this.authToken,
                WorkSpaceSid: this.workSpaceSid,
                TaskSid: reservation.taskSid,
                ConferenceSid: conferenceSid['conferenceSid']
              })
              .toPromise();

            await this.http
              .post('AddConferenceName', {
                AccountSid: this.accountSid,
                AuthToken: this.authToken,
                WorkSpaceSid: this.workSpaceSid,
                TaskSid: reservation.taskSid,
                ConferenceName: this.conferenceFriendlyName
              })
              .toPromise();

            await this.addParty(conferenceSid['conferenceSid'], reservation.task.attributes.from, this.outboundNumber);

            await this.setConnection(connection, reservation);
            await this.returnToPreviousPresence();
            break;
          }
        }
      }
    } catch (e) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Failed to accept outbound voice task.`, e);
    }
  }

  /** ***********************************************************
    Conversations
  /*************************************************************/
  private async initializeConversationClient() {
    const functionName = `initializeConversationClient`;
    try {
      this.loggerService.log(LOG_LEVEL.Debug, functionName, `Initializing Conversation Client.`);
      this.conversationClient.on(CONVERSATION_EVENT.initialized, this.conversationInitialized.bind(this));
      this.conversationClient.on(CONVERSATION_EVENT.initFailed, this.conversationInitFailed.bind(this));
      this.conversationClient.on(CONVERSATION_EVENT.connectionStateChanged, this.conversationConnectionStateChanged.bind(this));
      this.conversationClient.on(CONVERSATION_EVENT.connectionError, this.conversationConnectionError.bind(this));
      this.conversationClient.on(CONVERSATION_EVENT.tokenAboutToExpire, this.conversationTokenAboutToExpire.bind(this));
      this.conversationClient.on(CONVERSATION_EVENT.tokenExpired, this.conversationTokenExpired.bind(this));
    } catch (e) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Failed to initialize Conversation Client.`, e);
    }
  }

  private async conversationInitialized() {
    const functionName = `conversationInitialized`;
    try {
      this.loggerService.log(LOG_LEVEL.Debug, functionName, `Conversation Client initialized.`);
      this.conversationClientInitialized = true;
      this.checkIfAllInitialized();
    } catch (e) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Failed to initialize Conversation Client.`, e);
    }
  }

  private async conversationInitFailed() {
    const functionName = `conversationInitFailed`;
    try {
      if (this.allInitialized) {
        this.loggerService.log(LOG_LEVEL.Critical, functionName, `Failed to initialize Conversation Client.`);
      } else {
        this.loggerService.log(LOG_LEVEL.Information, functionName, `Failed to initialize Conversation Client, but not all services have been initialized.`);
      }
    } catch (e) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Failed to initialize Conversation Client.`, e);
    }
  }

  private async conversationConnectionStateChanged(state: CONVERSATION_STATE) {
    const functionName = `conversationConnectionStateChanged`;
    try {
      if (this.previousConversationState === CONVERSATION_STATE.disconnected && state === CONVERSATION_STATE.connected) {
        this.loggerService.log(LOG_LEVEL.Debug, functionName, `Conversation Client reconnected. Attempting to fetch reservations.`);
        sendNotification('SMS reconnected.', NOTIFICATION_TYPE.Information);
        this.worker.fetchReservations(this.fetchReservations.bind(this));
      } else if (state === CONVERSATION_STATE.disconnected) {
        this.reInitConversationListeners = true;
      }
      this.previousConversationState = state;
      this.loggerService.log(LOG_LEVEL.Information, functionName, `Connection state changed.`, state);
    } catch (e) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Failed to change connection state.`, e);
    }
  }

  private async conversationConnectionError(error) {
    const functionName = `conversationConnectionError`;
    try {
      if (this.allInitialized) {
        this.loggerService.log(LOG_LEVEL.Critical, functionName, `Conversation Client connection error.`, error);
        sendNotification('SMS connection error. Attempting to reconnect. If this takes too long try refreshing your page.', NOTIFICATION_TYPE.Error);
        // TODO: Create a set timeout to ask the agent to refresh the page if the connection doesn't come back up.
        this.previousConversationState = CONVERSATION_STATE.disconnected;
      } else {
        this.loggerService.log(LOG_LEVEL.Trace, functionName, `Conversation Client connection error, everything hasn't initialized yet.`);
      }
    } catch (e) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Failed to change connection error.`, e);
    }
  }

  private async conversationTokenAboutToExpire() {
    const functionName = `conversationTokenAboutToExpire`;
    try {
      this.loggerService.log(LOG_LEVEL.Debug, functionName, `Conversation Client token about to expire.`);
      this.refreshTokens();
    } catch (e) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Failed Conversation Client token about to expire.`, e);
    }
  }

  private async conversationTokenExpired() {
    const functionName = `conversationTokenExpired`;
    try {
      this.loggerService.log(LOG_LEVEL.Debug, functionName, `Conversation Client token expired.`);
      this.refreshTokens();
    } catch (e) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Failed Conversation Client token expired.`, e);
    }
  }

  async updateConversationClientToken() {
    const functionName = `updateConversationClientToken`;
    try {
      this.loggerService.log(LOG_LEVEL.Debug, functionName, `Updating Conversation Client token.`);
      this.conversationClient.updateToken(this.chatToken);
    } catch (e) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Failed to update Conversation Client token.`, e);
    }
  }

  private async createConversationListeners() {
    const functionName = `createConversationListeners`;
    try {
      this.loggerService.log(LOG_LEVEL.Debug, functionName, `Creating Conversation listeners.`);
      this.conversationClient.on(CONVERSATION_EVENT.conversationAdded, this.conversationAdded.bind(this));
      this.conversationClient.on(CONVERSATION_EVENT.conversationLeft, this.conversationLeft.bind(this));
      this.conversationClient.on(CONVERSATION_EVENT.messageAdded, this.messageAdded.bind(this));
      // TODO: Check for other events to listen to
      if (this.checkConversations) {
        this.sendCheckConversations();
      }
      this.reInitConversationListeners = false;
    } catch (e) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Failed to create conversation listeners.`, e);
    }
  }

  private async conversationAdded(conversation) {
    const functionName = `conversationAdded`;
    try {
      this.loggerService.log(LOG_LEVEL.Information, functionName, `Conversation added.`, conversation);
      // const twilioInteraction = this.getTwilioInteractionByConversationSid(conversation.sid);
      // if (!twilioInteraction) {
      //   this.loggerService.log(LOG_LEVEL.Loop, functionName, `No interaction found for conversation sid: ${conversation.sid}. Leaving Conversation.`);
      //   this.leaveConversation(conversation.sid);
      // }
    } catch (e) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Failed to add conversation.`, e);
    }
  }

  private async conversationLeft(conversation) {
    const functionName = `conversationLeft`;
    try {
      this.loggerService.log(LOG_LEVEL.Information, functionName, `Conversation left.`, conversation.sid);
      const twilioInteraction = this.getTwilioInteractionByConversationSid(conversation.sid);
      if (twilioInteraction && twilioInteraction.interaction.reservation.state === RESERVATION_STATE.accepted) {
        this.loggerService.log(LOG_LEVEL.Debug, functionName, `Conversation left, but reservation is still active. Rejoining.`, conversation.sid);
        await this.joinConversation(conversation.sid);
      }
    } catch (e) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Failed to leave conversation.`, e);
    }
  }

  private async messageAdded(message) {
    const functionName = `messageAdded`;
    try {
      this.loggerService.log(LOG_LEVEL.Information, functionName, `Message added to conversation.`, message.conversation.sid);
      this.loggerService.log(LOG_LEVEL.Trace, functionName, `Message body`, message);
      const twilioInteraction = this.getTwilioInteractionByConversationSid(message.conversation.sid);
      if (twilioInteraction) {
        if (twilioInteraction.interaction.reservation.reservationStatus !== RESERVATION_STATE.pending) {
          // TODO: Make sure that chat.messages exists
          const newMessage = {
            author: message.author,
            body: message.body,
            timestamp: message.dateCreated
          };
          this.loggerService.log(LOG_LEVEL.Trace, functionName, `TwilioInteraction:`, twilioInteraction.interaction);
          twilioInteraction.interaction.chat.messages.push(newMessage);
          this.nextTwilioInteraction(twilioInteraction);
          // TODO: find out a way to prevent this from causing race conditions when completing an SMS.
          // this.generateSetInteraction(twilioInteraction.interaction, INTERACTION_STATES.Connected);
        } else {
          this.loggerService.log(LOG_LEVEL.Trace, functionName, `Message not added to conversation, reservation is pending.`, message.conversation.sid);
        }
      } else {
        this.loggerService.log(LOG_LEVEL.Trace, functionName, `Message not added to conversation, no interaction found.`, message.conversation.sid);
      }
    } catch (e) {
      sendNotification('Failed to add message to conversation.', NOTIFICATION_TYPE.Error);
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Failed to add message.`, e);
    }
  }

  public async sendMessage(sid: string, message: string) {
    const functionName = `sendMessage`;
    try {
      this.loggerService.log(LOG_LEVEL.Debug, functionName, `Sending message for interaction.`, sid);
      const twilioInteraction = this.getTwilioInteractionByTaskSid(sid);
      this.loggerService.log(LOG_LEVEL.Trace, functionName, `TwilioInteraction:`, twilioInteraction.interaction);
      const activeConversation = await this.conversationClient.getConversationBySid(twilioInteraction.interaction.conversationSid);
      await activeConversation.sendMessage(message);
      // TODO: Add Timeout for if the message doesn't get added in a certain amount of time
    } catch (error) {
      try {
        if (error.body.message.includes('User not participant of conversation')) {
          this.loggerService.log(LOG_LEVEL.Error, functionName, `User not part of the conversation. Attempting to rejoin.`, error);
          this.reSendMessage(sid, message);
        } else {
          sendNotification('Send message failed. Please try again.', NOTIFICATION_TYPE.Error);
          this.loggerService.log(LOG_LEVEL.Error, functionName, `Failed to send message.`, error);
        }
      } catch (e) {
        this.loggerService.log(LOG_LEVEL.Error, functionName, `Failed to trigger resend message.`, e);
      }
    }
  }

  public async reSendMessage(sid: string, message: string) {
    const functionName = `reSendMessage`;
    try {
      this.loggerService.log(LOG_LEVEL.Warning, functionName, `Attempting to rejoin conversation before sending.`, { taskSid: sid });
      const twilioInteraction = this.getTwilioInteractionByTaskSid(sid);
      await this.joinConversation(twilioInteraction.interaction.conversationSid);
      const activeConversation = await this.conversationClient.getConversationBySid(twilioInteraction.interaction.conversationSid);
      this.loggerService.log(LOG_LEVEL.Debug, functionName, `Resending message for conversation.`, { conversationSid: twilioInteraction.interaction.conversationSid });
      await activeConversation.sendMessage(message);
    } catch (e) {
      sendNotification('Send message failed. Please try again.', NOTIFICATION_TYPE.Error);
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Failed to resend message.`, e);
    }
  }

  private async joinConversation(conversationSid: string) {
    const functionName = `joinConversation`;
    try {
      this.loggerService.log(LOG_LEVEL.Information, functionName, `Joining conversation.`, { conversationSid: conversationSid });
      const conversation = await this.conversationClient.getConversationBySid(conversationSid);
      if (!conversation) {
        this.loggerService.log(LOG_LEVEL.Error, functionName, `No conversation found.`, { conversationSid: conversationSid });
      }
      this.loggerService.log(LOG_LEVEL.Trace, functionName, `Adding user to conversation.`, this.username);
      await conversation.join();
      this.loggerService.log(LOG_LEVEL.Information, functionName, `Successfully joined conversation.`, { conversationSid: conversationSid });
    } catch (error) {
      if (!error.message.includes('Conflict')) {
        this.loggerService.log(LOG_LEVEL.Error, functionName, `Failed to join conversation.`, error);
        sendNotification('Failed to join conversation. Refresh the page to try again.', NOTIFICATION_TYPE.Error);
      } else {
        this.loggerService.log(LOG_LEVEL.Debug, functionName, `User already exists in conversation.`, { conversationSid: conversationSid, error: error });
      }
    }
  }

  private async leaveConversation(conversationSid: string) {
    const functionName = `leaveConversation`;
    try {
      this.loggerService.log(LOG_LEVEL.Information, functionName, `Leaving conversation.`, conversationSid);
      const conversation = await this.conversationClient.getConversationBySid(conversationSid);
      if (conversation) {
        this.loggerService.log(LOG_LEVEL.Trace, functionName, `Attempting to leave conversation.`, conversationSid);
        await conversation.leave();
      } else {
        this.loggerService.log(LOG_LEVEL.Warning, functionName, `Conversation not found.`, conversationSid);
      }
      this.loggerService.log(LOG_LEVEL.Information, functionName, `Successfully left conversation.`, conversationSid);
    } catch (error) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Failed to leave conversation.`, error);
    }
  }

  public async sendOutboundSMS(to: string) {
    const functionName = `sendOutboundSMS`;
    try {
      // TODO: Discuss how to handle this when in a multichannel
      this.loggerService.log(LOG_LEVEL.Information, functionName, `Sending outbound SMS to:`, to);
      await this.createOutboundTask('SMSFriendly', this.formatPhoneNumber(to, this.phoneNumberFormat), CHANNELS.SMS);
    } catch (e) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Failed to create outbound.`, e);
      sendNotification('Failed to create outbound SMS.', NOTIFICATION_TYPE.Error);
    }
  }

  public async checkOrCreateConversation(reservation: IReservation): Promise<string> {
    const functionName = `checkOrCreateConversation`;
    try {
      this.loggerService.log(LOG_LEVEL.Debug, functionName, `Checking if there is an existing conversation for reservation:`, reservation.taskSid);
      this.loggerService.log(LOG_LEVEL.Trace, functionName, `Reservation`, reservation);
      if (await this.checkIfConversationExists(reservation.task.attributes.conversationSid)) {
        this.loggerService.log(LOG_LEVEL.Debug, functionName, `Interaction already has Conversation Sid:`, reservation.task.attributes.conversationSid);
        const conversation = await this.conversationClient.getConversationBySid(reservation.task.attributes.conversationSid);
        if (conversation) {
          this.loggerService.log(LOG_LEVEL.Debug, functionName, `Conversation found:`, conversation.sid);
          return conversation.sid;
        } else {
          return await this.createConversation(reservation);
        }
      }
    } catch (e) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Failed to check or create conversation.`, e);
      return this.createConversation(reservation);
    }
  }

  private async createConversation(reservation: IReservation): Promise<string> {
    const functionName = `createConversation`;
    try {
      this.loggerService.log(LOG_LEVEL.Debug, functionName, `Creating conversation in backend for reservation:`, reservation);
      const response = await this.http
        .post('CreateConversation', {
          AccountSid: this.accountSid,
          AuthToken: this.authToken,
          To: reservation.task.attributes.from,
          From: this.outboundNumber
        })
        .toPromise();
      const conversationSid = response['conversationSid'];
      if (conversationSid) {
        this.loggerService.log(LOG_LEVEL.Warning, functionName, `New Conversation made. A conversation did not exist where one was expected.`, conversationSid);
        return conversationSid;
      } else if (response['exception']) {
        throw new Error(response['exception']);
      }
    } catch (e) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Failed to create conversation.`, e);
    }
  }

  public async checkIfConversationExists(conversationSid: string): Promise<boolean> {
    const functionName = `checkIfConversationExists`;
    try {
      this.loggerService.log(LOG_LEVEL.Debug, functionName, `Checking if conversation exists:`, conversationSid);
      const conversation = await this.conversationClient.getConversationBySid(conversationSid);
      if (conversation) {
        this.loggerService.log(LOG_LEVEL.Debug, functionName, `Conversation found:`, conversation.sid);
        return true;
      }
    } catch (e) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Failed to check if conversation exists.`, e);
    }
    return false;
  }

  private async getMessagesFromConversation(conversationSid: string) {
    const functionName = `getMessagesFromConversations`;
    try {
      this.loggerService.log(LOG_LEVEL.Debug, functionName, `Getting messages for conversation:`, conversationSid);
      const messages = [];
      const conversation = await this.conversationClient.getConversationBySid(conversationSid);
      const conversationMessages = await conversation.getMessages(this.messagePageSize);
      const messageList = conversationMessages.items;
      for (const message of messageList) {
        messages.push({
          author: message.author,
          body: message.body,
          timestamp: message.dateCreated
        });
      }
      return messages;
    } catch (e) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Failed to pull messages from conversation:`, conversationSid);
      return [];
    }
  }

  public async sendCheckConversations() {
    const functionName = `sendCheckConversations`;
    try {
      const conversationCleanupTimeStamp = this.storageService.getConversationCleanup();
      if (conversationCleanupTimeStamp > 0 && Date.now() - conversationCleanupTimeStamp < 60 * 60 * 1000 * this.checkConversationsTimer) {
        this.loggerService.log(LOG_LEVEL.Information, functionName, `Already sent check conversations within configured time period.`);
      } else {
        this.storageService.setConversationCleanup();
        this.loggerService.log(LOG_LEVEL.Debug, functionName, `Sending check for conversations without tasks.`);
        const response = await this.http
          .post('CreateConversationTask', {
            AccountSid: this.accountSid,
            AuthToken: this.authToken,
            WorkSpaceSid: this.workSpaceSid,
            WorkflowSid: this.directWorkflowSid,
            RequestUrl: this.checkConversationsUrl,
            RequestFrequency: this.checkConversationsTimer
          })
          .toPromise();
        this.loggerService.log(LOG_LEVEL.Debug, functionName, `Check conversations event sent.`, response);
      }
    } catch (e) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Unable to send event to check for conversations without tasks.`, e);
    }
  }

  public getTranscriptFromInteraction(twilioInteraction: ITwilioInteraction) {
    const functionName = `getTranscriptFromInteraction`;
    try {
      this.loggerService.log(LOG_LEVEL.Debug, functionName, `Getting Transcript from interaction.`, twilioInteraction.reservation.task.sid);
      const conversationMessages = twilioInteraction.chat && twilioInteraction.chat.messages ? twilioInteraction.chat.messages : [];
      let conversationTranscript = '';
      // TODO: Add logic to get the last X messages from the conversation
      for (let i = 0; i < conversationMessages.length; i++) {
        let messageAuthor;
        if (this.sendingOutboundSMS) {
          messageAuthor = conversationMessages[i]['author'] === this.username ? 'From: ' : 'To: ';
        } else {
          messageAuthor = conversationMessages[i]['author'] === this.username ? 'To: ' : 'From: ';
        }
        conversationTranscript += messageAuthor + conversationMessages[i]['body'] + '\n';
      }
      return conversationTranscript;
    } catch (error) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Failed to parse transcript.`, error);
    }
  }

  /** ***********************************************************
    Controls
  /*************************************************************/
  public async answer(twilioInteraction: ITwilioInteraction, statusCallback: string) {
    const functionName = `answer`;
    try {
      // TODO: Add logging}
      this.loggerService.log(LOG_LEVEL.Debug, functionName, 'Answer event triggered.', { taskSid: twilioInteraction.reservation?.taskSid, reservationSid: twilioInteraction.reservation?.sid });
      const isVoice = this.reservationIsVoice(twilioInteraction.reservation);
      if (isVoice) {
        // TODO: Move this to reservation accepted for voice
        // Incoming Blind Transfer
        try {
          if (twilioInteraction.reservation.task.attributes.blindTransfer !== undefined) {
            if (twilioInteraction.reservation.task.attributes.blindTransfer.toString() === 'false') {
              twilioInteraction.incomingWarmTransfer = true;
            }
            this.acceptConference(twilioInteraction, twilioInteraction.reservation.task.attributes.from);
          } else if (twilioInteraction.reservation.task.attributes.conference !== undefined) {
            this.acceptConference(twilioInteraction, twilioInteraction.reservation.task.attributes.from);
          } else {
            const reservation = await this.http
              .post('AcceptCallTask', {
                AccountSid: this.accountSid,
                AuthToken: this.authToken,
                WorkspaceSid: this.workSpaceSid,
                TaskSid: twilioInteraction.reservation.taskSid,
                ReservationSid: twilioInteraction.reservation.sid,
                StatusCallback: this.statusCallback,
                From: this.outboundNumber
              })
              .toPromise();
          }
        } catch (error) {
          this.loggerService.log(LOG_LEVEL.Error, functionName, `Unable to answer call`, error);
        }
      } else {
        this.loggerService.log(LOG_LEVEL.Trace, functionName, `Answering SMS conversation.`, twilioInteraction);
        twilioInteraction.reservation.accept();
      }
    } catch (e) {
      sendNotification('Answer failed. Please try again.', NOTIFICATION_TYPE.Error);
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Answer failed.`, e);
    }
  }

  // eslint-disable-next-line max-statements
  public async hangup(twilioInteraction: ITwilioInteraction) {
    const functionName = `hangup`;
    try {
      // TODO: Add spam protection?
      this.loggerService.log(LOG_LEVEL.Debug, functionName, `Hangup event triggered.`, twilioInteraction);
      const isVoice = this.reservationIsVoice(twilioInteraction.reservation);
      if (isVoice) {
        this.loggerService.log(LOG_LEVEL.Information, functionName, `Hanging up voice call.`, twilioInteraction);
        if (twilioInteraction.parties != null && twilioInteraction.parties.length === 0) {
          this.wrapup(twilioInteraction, true);
        } else {
          twilioInteraction.connection.disconnect();
        }
      } else {
        this.loggerService.log(LOG_LEVEL.Information, functionName, `Hanging up sms conversation.`, twilioInteraction);
        this.outboundSMSNumber = ''; // TODO: remove this when refactoring Voice
        this.sendingOutboundSMS = false; // TODO: remove this when refactoring Voice
        await this.workerCompleteTask(twilioInteraction.reservation ? twilioInteraction.reservation.taskSid : twilioInteraction.taskSid);
      }
    } catch (e) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Hangup failed.`, e);
      sendNotification('Hangup failed. Please try again.', NOTIFICATION_TYPE.Error);
    }
  }

  public async createConference(twilioInteraction: ITwilioInteraction) {
    const functionName = `createConference`;
    try {
      this.nextTwilioInteraction(this.twilioInteractions[twilioInteraction.taskSid]);
      if (twilioInteraction.connection['customParameters'].get('friendlyName') === undefined) {
        // Inbound Call
        await contextualOperation(CONTEXTUAL_OPERATION_TYPE.Conference, CHANNEL_TYPES.Telephony)
          .catch((error) => {
            if (error !== 'Canceled by user!') {
              this.loggerService.log(LOG_LEVEL.Error, functionName, `Inbound Conference failed.`, error);
              sendNotification('Conference failed.', NOTIFICATION_TYPE.Error);
            }
          })
          .then(async (contact) => {
            if (this.holdOnTransfer) {
              await this.holdForConference(twilioInteraction);
            }
            await this.sendConference(twilioInteraction, contact['uniqueId'], this.username, twilioInteraction.taskSid);
            this.nextTwilioInteraction(this.twilioInteractions[twilioInteraction.taskSid]);
          });
      } else {
        // Outbound Call
        await contextualOperation(CONTEXTUAL_OPERATION_TYPE.Conference, CHANNEL_TYPES.Telephony)
          .catch((error) => {
            if (error !== 'Canceled by user!') {
              this.loggerService.log(LOG_LEVEL.Error, functionName, `Outbound Conference failed.`, error);
              sendNotification('Conference failed.', NOTIFICATION_TYPE.Error);
            }
          })
          .then(async (contact) => {
            const conferenceSid = await this.getConferenceSid(twilioInteraction.connection['customParameters'].get('friendlyName'));
            if (this.holdOnTransfer) {
              await this.holdForConference(twilioInteraction);
            }
            await this.sendConference(twilioInteraction, contact['uniqueId'], this.username, twilioInteraction.taskSid, conferenceSid['conferenceSid']);
            this.nextTwilioInteraction(this.twilioInteractions[twilioInteraction.taskSid]);
          });
      }
    } catch (e) {
      this.nextTwilioInteraction(this.twilioInteractions[twilioInteraction.taskSid]);
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Conference failed.`, e);
    }
  }

  public async sendConference(twilioInteraction: ITwilioInteraction, to: any, from: string, taskSid: string, conferenceSid?: string) {
    const functionName = `sendConference`;
    try {
      const filteredTo = to.replace(/[-()]/g, '');
      const isInternal = isNaN(filteredTo); // True if this is an internal transfer, false if this is an external transfer
      if (conferenceSid === undefined) {
        let response = await this.http
          .post('GetConferenceSid', {
            AccountSid: this.accountSid,
            AuthToken: this.authToken,
            friendlyName: twilioInteraction.connection['customParameters'].get('friendlyName')
          })
          .toPromise();
        conferenceSid = response['conferenceSid'];
        if (conferenceSid === undefined) {
          response = await this.http
            .post('GetConferenceFromTask', {
              AccountSid: this.accountSid,
              AuthToken: this.authToken,
              WorkSpaceSid: this.workSpaceSid,
              TaskSid: twilioInteraction.reservation.task.sid
            })
            .toPromise();
          conferenceSid = response['conferenceSid'];
        }
      }
      await this.endConferencOnExit(conferenceSid, false);
      await this.http
        .post('Conference', {
          accountSid: this.accountSid,
          authToken: this.authToken,
          workspaceSid: this.workSpaceSid,
          workflowSid: this.directWorkflowSid,
          to: to,
          from: from,
          outboundNumber: this.outboundNumber,
          isInternal: isInternal,
          taskSid: taskSid,
          conferenceSid: conferenceSid,
          workerCallSid: twilioInteraction.connection.parameters.CallSid
        })
        .toPromise();
      this.nextTwilioInteraction(this.twilioInteractions[twilioInteraction.taskSid]);
    } catch (e) {
      this.nextTwilioInteraction(this.twilioInteractions[twilioInteraction.taskSid]);
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Conference failed.`, e);
      sendNotification('Conference Failed.', NOTIFICATION_TYPE.Error);
    }
  }

  public async blindTransfer(twilioInteraction: ITwilioInteraction, blindTransfer?: boolean) {
    const functionName = `blindTransfer`;
    try {
      twilioInteraction.isBlindTransfering = blindTransfer;
      this.nextTwilioInteraction(this.twilioInteractions[twilioInteraction.taskSid]);

      if (twilioInteraction.connection['customParameters'].get('friendlyName') === undefined) {
        // internal
        await contextualOperation(CONTEXTUAL_OPERATION_TYPE.BlindTransfer, CHANNEL_TYPES.Telephony)
          .catch((error) => {
            if (error !== 'Canceled by user!') {
              this.loggerService.log(LOG_LEVEL.Error, functionName, `internal blind transfer failed`, error);
              sendNotification('Transfer failed.', NOTIFICATION_TYPE.Error);
            }
          })
          .then((contact) => {
            this.sendTransfer(twilioInteraction, contact['uniqueId'], twilioInteraction.connection['customParameters'].get('phone'), true, twilioInteraction.taskSid);
          });
      } else {
        // external
        await contextualOperation(CONTEXTUAL_OPERATION_TYPE.BlindTransfer, CHANNEL_TYPES.Telephony)
          .catch((error) => {
            if (error !== 'Canceled by user!') {
              this.loggerService.log(LOG_LEVEL.Error, functionName, `external blind transfer failed`, error);
              sendNotification('Transfer failed.', NOTIFICATION_TYPE.Error);
            }
          })
          .then(async (contact) => {
            const conferenceSid = await this.getConferenceSid(twilioInteraction.connection['customParameters'].get('friendlyName'));
            await this.sendTransfer(twilioInteraction, contact['uniqueId'], this.outboundNumber, true, twilioInteraction.taskSid, conferenceSid['conferenceSid']);
          });
      }
    } catch (error) {
      twilioInteraction.isBlindTransfering = null;
      this.nextTwilioInteraction(this.twilioInteractions[twilioInteraction.taskSid]);
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Unable to blind transfer`, error);
    }
  }

  public async warmTransfer(twilioInteraction: ITwilioInteraction, warmTransfer?: boolean) {
    const functionName = 'warmTransfer';
    try {
      twilioInteraction.isWarmTransfering = warmTransfer;
      this.nextTwilioInteraction(this.twilioInteractions[twilioInteraction.taskSid]);
      if (twilioInteraction.connection['customParameters'].get('friendlyName') === undefined) {
        // Inbound Call
        await contextualOperation(CONTEXTUAL_OPERATION_TYPE.WarmTransfer, CHANNEL_TYPES.Telephony)
          .catch((error) => {
            if (error !== 'Canceled by user!') {
              this.loggerService.log(LOG_LEVEL.Error, functionName, `inbound warm transfer failed`, error);
              sendNotification('Transfer failed.', NOTIFICATION_TYPE.Error);
            }
          })
          .then(async (contact) => {
            if (this.holdOnTransfer) {
              await this.holdForConference(twilioInteraction);
            }
            await this.sendTransfer(twilioInteraction, contact['uniqueId'], this.username, false, twilioInteraction.taskSid);
            twilioInteraction.confirmingWarmTransfer = true;
            twilioInteraction.isWarmTransfering = null;
            this.nextTwilioInteraction(this.twilioInteractions[twilioInteraction.taskSid]);
          });
      } else {
        // Outbound Call
        await contextualOperation(CONTEXTUAL_OPERATION_TYPE.WarmTransfer, CHANNEL_TYPES.Telephony)
          .catch((error) => {
            if (error !== 'Canceled by user!') {
              this.loggerService.log(LOG_LEVEL.Error, functionName, `outbound warm transfer failed`, error);
              sendNotification('Transfer failed.', NOTIFICATION_TYPE.Error);
            }
          })
          .then(async (contact) => {
            const conferenceSid = await this.getConferenceSid(twilioInteraction.connection['customParameters'].get('friendlyName'));
            if (this.holdOnTransfer) {
              await this.holdForConference(twilioInteraction);
            }
            await this.sendTransfer(twilioInteraction, contact['uniqueId'], this.username, false, twilioInteraction.taskSid, conferenceSid['conferenceSid']);
            twilioInteraction.confirmingWarmTransfer = true;
            twilioInteraction.isWarmTransfering = null;
            this.nextTwilioInteraction(this.twilioInteractions[twilioInteraction.taskSid]);
          });
      }
    } catch (error) {
      twilioInteraction.isWarmTransfering = null;
      twilioInteraction.confirmingWarmTransfer = false;
      this.nextTwilioInteraction(this.twilioInteractions[twilioInteraction.taskSid]);
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Unable to warm transfer`, error);
    }
  }

  public async sendTransfer(twilioInteraction: ITwilioInteraction, to: any, from: string, isBlind: boolean, taskSid: string, conferenceSid?: string) {
    const functionName = 'sendTransfer';
    try {
      const filteredTo = to.replace(/[-()]/g, '');
      const isInternal = isNaN(filteredTo); // True if this is an internal transfer, false if this is an external transfer
      if (conferenceSid === undefined) {
        let response = await this.http
          .post('GetConferenceSid', {
            AccountSid: this.accountSid,
            AuthToken: this.authToken,
            friendlyName: twilioInteraction.connection['customParameters'].get('friendlyName')
          })
          .toPromise();
        conferenceSid = response['conferenceSid'];
        if (conferenceSid === undefined) {
          response = await this.http
            .post('GetConferenceFromTask', {
              AccountSid: this.accountSid,
              AuthToken: this.authToken,
              WorkSpaceSid: this.workSpaceSid,
              TaskSid: twilioInteraction.reservation.task.sid
            })
            .toPromise();
          conferenceSid = response['conferenceSid'];
        }
      }
      await this.http
        .post('Transfer', {
          accountSid: this.accountSid,
          authToken: this.authToken,
          workspaceSid: this.workSpaceSid,
          workflowSid: this.directWorkflowSid,
          to: to,
          from: from,
          outboundNumber: this.outboundNumber,
          isBlind: isBlind,
          isInternal: isInternal,
          taskSid: taskSid,
          conferenceSid: conferenceSid,
          workerCallSid: twilioInteraction.connection.parameters.CallSid
        })
        .toPromise()
        .then((response) => {
          if (response['sid'] != null) {
            this.warmTransferTargetSid = response['sid'];
            this.warmTransferCheckInterval = setInterval(async () => {
              const canceled = await this.checkTaskStatus(response['sid'], 'canceled');
              if (canceled) {
                this.holdForConference(twilioInteraction);
                this.resetInteraction(twilioInteraction);
                clearInterval(this.warmTransferCheckInterval);
              }
            }, this.warmTransferCheckTimer);
          }
        });
      if (twilioInteraction.isBlindTransfering === true) {
        twilioInteraction.isBlindTransfering = null;
      }
      this.nextTwilioInteraction(this.twilioInteractions[twilioInteraction.taskSid]);
    } catch (e) {
      twilioInteraction.isWarmTransfering = false;
      twilioInteraction.confirmingWarmTransfer = false;
      this.nextTwilioInteraction(this.twilioInteractions[twilioInteraction.taskSid]);
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Unable to send transfer`, e);
      sendNotification('Transfer Failed.', NOTIFICATION_TYPE.Error);
    }
  }

  public async dtmf(twilioInteraction: ITwilioInteraction) {
    const functionName = 'dtmf';
    try {
      await contextualOperation(CONTEXTUAL_OPERATION_TYPE.DTMF, CHANNEL_TYPES.Telephony, async (contact) => {
        await twilioInteraction.connection.sendDigits(contact.uniqueId);
      })
        .then(async (contact) => {})
        .catch((error) => {
          this.loggerService.log(LOG_LEVEL.Error, `dtmfCallback`, `DTMF failed`, error);
        });
    } catch (error) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, `DTMF failed`, error);
    }
  }

  public async mute(twilioInteraction: ITwilioInteraction) {
    const functionName = 'mute';
    try {
      twilioInteraction.connection.mute(true);
      return await this.http
        .post('MuteParty', {
          AccountSid: this.accountSid,
          AuthToken: this.authToken,
          ConferenceSid: twilioInteraction.reservation.task.attributes.conference['sid'],
          CallSid: twilioInteraction.connection.parameters.CallSid,
          Mute: true
        })
        .toPromise();
    } catch (e) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Mute failed`, e);
      sendNotification('Mute call Failed. Please try again.', 2);
    }
  }

  public async unmute(twilioInteraction: ITwilioInteraction) {
    const functionName = 'unmute';
    try {
      twilioInteraction.connection.mute(false);
      return await this.http
        .post('MuteParty', {
          AccountSid: this.accountSid,
          AuthToken: this.authToken,
          ConferenceSid: twilioInteraction.reservation.task.attributes.conference['sid'],
          CallSid: twilioInteraction.connection.parameters.CallSid,
          Mute: false
        })
        .toPromise();
    } catch (e) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Unmute failed`, e);
      sendNotification('Unmute call failed. Please try again.', NOTIFICATION_TYPE.Error);
    }
  }

  public reject(twilioInteraction: ITwilioInteraction) {
    const functiuonName = 'reject';
    try {
      this.loggerService.log(LOG_LEVEL.Debug, functiuonName, `Rejecting`, { reservationSid: twilioInteraction.reservation.sid, taskSid: twilioInteraction.reservation.taskSid });
      if (twilioInteraction.reservation.task.attributes['blindTransfer'] != null && twilioInteraction.reservation.task.attributes['blindTransfer'].toString() === 'false') {
        this.cancelTask(twilioInteraction.reservation.taskSid);
      } else if (twilioInteraction.reservation.task.attributes['conferencing'] != null && twilioInteraction.reservation.task.attributes['conferencing'].toString() === 'true') {
        this.cancelTask(twilioInteraction.reservation.taskSid);
      } else {
        twilioInteraction.reservation.reject();
      }
      if (this.incomingPhoneRinger && !this.incomingPhoneRinger.paused) {
        this.incomingPhoneRinger.pause();
      }
      if (this.incomingChatSMSRinger && !this.incomingChatSMSRinger.paused) {
        this.incomingChatSMSRinger.pause();
      }
    } catch (e) {
      this.loggerService.log(LOG_LEVEL.Error, functiuonName, `Reject failed`, e);
      sendNotification('Reject call failed. Please try again.', NOTIFICATION_TYPE.Error);
    }
  }

  public async hold(twilioInteraction: ITwilioInteraction, hold = true, transfering = false) {
    const functionName = 'hold';
    try {
      if (hold === false) {
        // When unholding one device we put the rest on hold first
        await this.holdDevices();
      }
      const result = this.http
        .post('Hold', {
          callSid: twilioInteraction.connection.parameters.CallSid,
          hold
        })
        .toPromise();

      // Note: it might be better if we could keep track of held calls server side or twilio side
      if (!transfering) {
        result.then(() => {
          this.twilioInteractions[twilioInteraction.taskSid].interaction.isHeld = hold;
          this.nextTwilioInteraction(this.twilioInteractions[twilioInteraction.taskSid]);
        });
      }
      return result;
    } catch (e) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Hold failed`, e);
      sendNotification('Hold call failed. Please try again', NOTIFICATION_TYPE.Error);
    }
  }

  public async initiateOutbound(to: string) {
    const functionName = 'initiateOutbound';
    try {
      if (!this.checkDevices('ready')) {
        // if there isn't a ready device throw an error
        sendNotification('Maximum number of active calls reached.', NOTIFICATION_TYPE.Error);
        return;
      }
      await this.holdDevices();
      for (const device of this.devices) {
        // Check for a ready device, if there is one connect to it
        if (device) {
          if (device.status() === 'ready') {
            this.createOutboundTask(this.conferenceFriendlyName, to);
            return;
          }
        }
      }
    } catch (e) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Outbound dial failed`, e);
      sendNotification('Outbound dial failed. Please try again.', NOTIFICATION_TYPE.Error);
    }
  }

  public focus(twilioInteraction: ITwilioInteraction) {
    const functionName = `focus`;
    try {
      this.loggerService.logger.logTrace(`${functionName} : Focussing interaction. Interaction: ${JSON.stringify(twilioInteraction)}`);
      this.storageService.setOnFocus(twilioInteraction.taskSid);
      this.generateSetInteraction(twilioInteraction, INTERACTION_STATES.Connected);
    } catch (e) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Focus failed`, e);
      sendNotification('focus failed. Please try again.', NOTIFICATION_TYPE.Error);
    }
  }

  public async addParty(conferenceSid: string, to: string, from: string) {
    return await this.http
      .post('addParty', {
        AccountSid: this.accountSid,
        AuthToken: this.authToken,
        ConferenceSid: conferenceSid,
        From: from,
        To: to
      })
      .toPromise();
  }

  public async cancelWarmTransfer(twilioInteraction: ITwilioInteraction) {
    this.cancelTask(this.warmTransferTargetSid);
  }

  public async cancelTask(taskSid: string) {
    const functionName = 'cancelTask';
    try {
      return await this.http
        .post('cancelTask', {
          accountSid: this.accountSid,
          authToken: this.authToken,
          workSpaceSid: this.workSpaceSid,
          taskSid: taskSid
        })
        .toPromise();
    } catch (e) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Cancel task failed`, e);
    }
  }

  public async getTask(taskSid: string) {
    const functionName = 'getTask';
    try {
      return await this.http
        .post('getTask', {
          accountSid: this.accountSid,
          authToken: this.authToken,
          workSpaceSid: this.workSpaceSid,
          taskSid: taskSid
        })
        .toPromise();
    } catch (e) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, `Get task failed`, e);
    }
  }

  public async checkTaskStatus(taskSid: string, status: string) {
    const task = await this.getTask(taskSid);
    return task['assignment_status'] === status;
  }

  public async removeParty(twilioInteraction: ITwilioInteraction, callSid: string) {
    if (twilioInteraction.confirmingWarmTransfer === true && callSid !== twilioInteraction.connection.parameters.CallSid) {
      twilioInteraction.confirmingWarmTransfer = null;
      twilioInteraction.isWarmTransfering = null;
    }
    await this.http
      .post('RemoveParty', {
        AccountSid: this.accountSid,
        AuthToken: this.authToken,
        ConferenceSid: twilioInteraction.reservation.task.attributes.conference['sid'],
        CallSid: callSid
      })
      .toPromise();
    await twilioInteraction.parties.forEach((party, index) => {
      if (party === callSid) {
        twilioInteraction.parties.splice(index, 1);
      }
    });
    await this.nextTwilioInteraction(this.getTwilioInteractionByTaskSid(twilioInteraction.reservation.taskSid));
  }

  public async holdParty(twilioInteraction: ITwilioInteraction, callSid: string) {
    return await this.http
      .post('HoldParty', {
        AccountSid: this.accountSid,
        AuthToken: this.authToken,
        ConferenceSid: twilioInteraction.reservation.task.attributes.conference['sid'],
        CallSid: callSid
      })
      .toPromise();
  }

  public async holdForConference(twilioInteraction: ITwilioInteraction) {
    if (twilioInteraction.parties != null && twilioInteraction.parties.length < 3) {
      for (let i = 0; i < twilioInteraction.parties.length; i++) {
        if (twilioInteraction.parties[i] !== twilioInteraction.connection.parameters.CallSid) {
          await this.holdParty(twilioInteraction, twilioInteraction.parties[i]);
        }
      }
    }
  }

  public async getHeldParticipants(twilioInteraction: ITwilioInteraction) {
    return await this.http
      .post('GetHeldParticipants', {
        AccountSid: this.accountSid,
        AuthToken: this.authToken,
        ConferenceSid: twilioInteraction.reservation.task.attributes.conference['sid']
      })
      .toPromise();
  }

  public async resetInteraction(twilioInteraction: ITwilioInteraction) {
    clearInterval(this.warmTransferCheckInterval);
    twilioInteraction.isBlindTransfering = null;
    twilioInteraction.isWarmTransfering = null;
    twilioInteraction.confirmingWarmTransfer = null;
    twilioInteraction.incomingWarmTransfer = null;
    this.nextTwilioInteraction(this.getTwilioInteractionByTaskSid(twilioInteraction.reservation.taskSid));
  }

  public getOutboundNumber() {
    return this.outboundNumber;
  }

  protected formatPhoneNumber(inputNumber: string, phoneNumberFormat: Object): string {
    const functionName = 'formatPhoneNumber';
    try {
      this.loggerService.log(LOG_LEVEL.Debug, functionName, 'Starting phonenumber formatting ' + inputNumber, phoneNumberFormat);
      const configuredInputFormats = Object.keys(phoneNumberFormat);
      for (let index = 0; index < configuredInputFormats.length; index++) {
        let formatCheck = true;
        const inputFormat = configuredInputFormats[index];
        const outputFormat = phoneNumberFormat[inputFormat];
        if (inputFormat.length === inputNumber.length) {
          const arrInputDigits = [];
          let outputNumber = '';
          let outputIncrement = 0;
          if ((inputFormat.match(/x/g) || []).length !== (outputFormat.match(/x/g) || []).length) {
            continue;
          }
          for (let j = 0; j < inputFormat.length; j++) {
            if (inputFormat[j] === 'x') {
              arrInputDigits.push(j);
            } else if (inputFormat[j] !== '?' && inputNumber[j] !== inputFormat[j]) {
              formatCheck = false;
              break;
            }
          }
          if (formatCheck) {
            for (let j = 0; j < outputFormat.length; j++) {
              if (outputFormat[j] === 'x') {
                outputNumber = outputNumber + inputNumber[arrInputDigits[outputIncrement]];
                outputIncrement++;
              } else {
                outputNumber = outputNumber + outputFormat[j];
              }
            }
            this.loggerService.log(LOG_LEVEL.Debug, functionName, 'Phonenumber formatted' + inputNumber, phoneNumberFormat);
            return outputNumber;
          }
        }
      }
    } catch (error) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, 'Error. ' + error);
    }
    this.loggerService.log(LOG_LEVEL.Debug, functionName, 'No formatting applied ' + inputNumber);
    return inputNumber;
  }
}

interface ICapabilityTokens {
  startingActivitySid: string;
  taskRouterToken: string;
  voiceToken: string;
  chatToken: string;
  accountSid: string;
  authToken: string;
}
